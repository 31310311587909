import axios from "axios";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import close from "../../assets/images/close.svg";
import { API_URL } from "../../lib/network/api";
import Loader from "../../components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toggleCreateCard } from '../../redux/action/showCreateCardSlice';
import autosize from "autosize";
import { MY_TASKS } from "../../constants/constant";
import profile from "../../assets/images/profile_image.png";

const CreateTask = ({getAllTask}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [streams, setStreams] = useState([]);
  const [selectedLocalStream, setSelectedLocalStream] = useState();
  const [selectedLocalMember, setSelectedLocalMember] = useState();
  const [selectedLocalPriority, setSelectedLocalPriority] = useState();
  const [members, setMembers] = useState([]);
  const [tags, setTags] = useState([]);
  const [taskExistingData, setTaskExistingData] = useState({});
  const [costInputValue, setCostInputValue] = useState();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [priorityClass, setPriorityClass] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const textareaRef = useRef(null);
  const [selectedMemberImage, setSelectedMemberImage] = useState('');

  const handleKeyDown1 = e => {
    e.preventDefault();
  };

  const [loader, setLoader] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);
  var currentDate = new Date();
  var formattedDateToday = currentDate.toISOString().slice(0, 10);
  useEffect(() => {
    const taskId = queryParams.get("id");
    const localStream = localStorage.getItem("selectedStream")
    const localMember = localStorage.getItem("selectedMember")
    const localPriority = localStorage.getItem("selectedPriority")
    setLoader(true);
    if (taskId) {
      fetchTaskData(taskId);
    }
    setLoader(false);
    setSelectedLocalStream(parseInt(localStream));
    setSelectedLocalMember(parseInt(localMember));
    if (localPriority == "High") {
      setSelectedLocalPriority('H');
    } else if (localPriority == "Medium") {
      setSelectedLocalPriority('M');
    } else if (localPriority == "Low") {
      setSelectedLocalPriority('L');
    }
  }, []);

  const fetchTaskData = async (taskId) => {
    try {
      const response = await axios.get(
        `${API_URL}task/taskById?task_id=${taskId}`
      );
      const fetchTaskData = response.data.data;
      if (
        !userData.user_id ||
        fetchTaskData.task_owner_id !== userData.user_id
      ) {
        navigate("/task");
        return;
      }
      setTaskExistingData(fetchTaskData);
      setTags(fetchTaskData.task_tags);
      fetchMembersByStreamId(fetchTaskData.stream);
      console.log("fetchTaskData.stream", fetchTaskData.stream)
    } catch (error) { }
  };
  useEffect(() => {
    axios.get(`${API_URL}task/stream?userId=${userData.user_id}`)
      .then(response => {
        console.log("response.data.data", response.data.data);
        let sStream = localStorage.getItem("selectedStream");
        if (localStorage.getItem("selectedStream")) {
          fetchMembersByStreamId(sStream);
        }

        if (response.data && Array.isArray(response.data.data)) {
          setStreams(response.data.data);

        } else {
          console.error('Unexpected response data format:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching streams:', error);
      });
  }, []);

  const fetchMembersByStreamId = (streamId) => { 
    console.log("fetch member api called:", streamId)


    if (streamId === null || streamId === "" || streamId === "null") {
      setMembers([]);
      return;
    }

    localStorage.setItem('CreateStream', streamId);


    if (streamId === MY_TASKS) {
      setMembers([{ member_id: userData?.user_id, member_name: userData?.user_name }]);
    } else {
      axios
        .get(`${API_URL}getMembersByStreamId`, {params:{
          stream_id: streamId,
    }})
        .then((response) => {
          if (response.data && response.data.code === 200) {
            console.log("response.data.data.members", response.data.data.members);
            localStorage.setItem('createStreamName', response.data.data.stream_name);
            setMembers(response.data.data.members);
          } else {
            console.error("Unexpected response data:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching members:", error);
        });
    }
  };
  const postTaskData = async (createTaskData) => {
    console.log("createTaskData", createTaskData.stream)
    try {
      const response = await axios.post(
        `${API_URL}task/create-task`,
        createTaskData
      );
      return response;
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message)
        setTimeout(() => {
          navigate('/dashboard');
        }, 1000); 
      }else{
      console.log("entered error", error);
      toast.error(error.response.data.message)
      return;
    }
    }
  };

  const removeHtmlTags = (value) => {
    return value.replace(/<[^>]*>/g, "");
  };

  const formik = useFormik({
    initialValues: {

      stream: selectedLocalStream || "",
      member: "",
      task_priority: "H" ,
      task_title: "",
      task_eta: formattedDateToday,
      task_details: "",
      task_tags: "",
      task_cost: "",
      task_time: "",
      // tagmembers: taskExistingData.allow_members_to_comment?.map(member => ({ value: member.id, label: member.name })) || [],
      tagmembers: taskExistingData.allow_members_to_comment?.map(member => ({ value: member.id, label: member.name })) || [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      stream: Yup.string()
        .notOneOf([""], "Please select a stream")
        .required("Stream is required"),
      member: Yup.string()
        .notOneOf([""], "Please select a member")
        .required("Member is required"),
      task_priority: Yup.string().required("Priority is required"),
      task_title: Yup.string()
        .required("Title is required")
        .max(100, "Title must be at most 100 characters")
        .test(
          "no-leading-space",
          "Title cannot start with a space",
          function(value) {
            if (value && value.startsWith(' ')) {
              return false; 
            }
            return true;
          }
        ),

      task_eta: Yup.string().required("ETA is required"),
      task_details: Yup.string()
        .transform(removeHtmlTags)
        .required("Task Detail is required")
        .max(5000, "Task Detail must be at most 5000 characters"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values , { resetForm }) => {
      console.log("values of tagged members", values.tagmembers);
      const selectedMemberIds = values.tagmembers.map(member => member.value);
      console.log("selectedMemberIds on submit ", selectedMemberIds);
      console.log("selectedMembers on submit ", selectedMembers);
      setLoader(true);
      let result;

      try {
          const taskAssignedTo = values.stream === MY_TASKS ? userData?.user_id : (values.member ? parseInt(values.member) : userData?.user_id);
          const createTaskData = {
            task_title: values.task_title,
            task_owner: userData?.user_name,
            task_eta: 0,
            eta_in_date_time: values.task_eta,
            task_priority: values.task_priority,
            stream: values.stream,
            task_details: values.task_details,
            task_assigned_to: taskAssignedTo,
            task_tags: tags,
            task_owner_id: userData?.user_id,
            task_cost: values.task_cost,
            task_time: values.task_time,
            allow_members_to_comment: selectedMembers.map(member => member.value),
          };
          console.log("create Task Data ", createTaskData);
          handleToggle();
          result = await postTaskData(createTaskData);

        if (!result || !result.data) {
          setLoader(false);
          return;
        }
        localStorage.removeItem("selectedPriority");
        localStorage.removeItem("selectedMember");
        // localStorage.removeItem("selectedStream");
        getAllTask();
        setTags([])
        resetForm();
      } catch (error) {
        setLoader(false);
      } finally {
        setLoader(false);
      }
    },
  }
  );

  const handleTimeChange = (e) => {
    let value = e.target.value;
    if (value === '' || value.endsWith('.')) {
      e.target.value = value;
      formik.setFieldValue("task_time", value);
      return;
    }
    value = value.replace(/[^\d.]/g, '');
    const [integerPart, decimalPart] = value.split('.');
    let truncatedIntegerPart = integerPart.slice(0, 4);
    truncatedIntegerPart = truncatedIntegerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    let truncatedDecimalPart = '';
    if (decimalPart) {
      truncatedDecimalPart = decimalPart.slice(0, 2);
    }
    value = `${truncatedIntegerPart}${truncatedDecimalPart ? `.${truncatedDecimalPart}` : ''}`;
    e.target.value = value;
    formik.setFieldValue("task_time", value);
  };
  // useEffect(() => {
  //   if (formik.values.stream) {
  //     localStorage.setItem("selectedStream", formik.values.stream);
  //   }
  // }, [formik.values.stream]);

  useEffect(() => {
    const storedStream = localStorage.getItem("selectedStream");
    if (storedStream == MY_TASKS) {
    }
  })
  const handleCostChange = (e) => {
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.,]/g, "");     // Allow only digits, commas, and decimals
    const [integerPart, decimalPart] = newValue.split(".");
    let formattedIntegerPart = integerPart.replace(/,/g, "").slice(0, 8);  // Limit integer part to 8 digits, removing existing commas first
    formattedIntegerPart = formattedIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");   // Add commas for thousands
    // Construct the new value with limited decimal part
    newValue = decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart.slice(0, 2)}`
      : formattedIntegerPart;
    setCostInputValue(newValue);
    formik.setFieldValue("task_cost", newValue);
  };

  const getMinDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`;
  };
  const savedStream = localStorage.getItem("selectedStream");
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = e.target.value;
      if (!value.trim()) return;
      setTags([...tags, value]);
      e.target.value = "";
    }
  }
  function handleTagsBlur(e) {
    setIsInputFocused(false); 
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([...tags, value]);
    e.target.value = "";
  }
  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }
  const handleEditorChange = (value) => {
    formik.setFieldValue("task_details", value);
    formik.setFieldTouched('task_details', true, false);
  };

  const dispatch = useDispatch();
  const showCard = useSelector(state => state.showCreateCard);

  const handleToggle = () => {
    formik.resetForm();
    formik.setTouched({
      task_details: false,
    });
    dispatch(toggleCreateCard());
  };

  useEffect(() => {
    if (formik.values.task_priority === "H") {
      setPriorityClass("customInput tag_priority");
    } else if (formik.values.task_priority === "M") {
      setPriorityClass("customInput tag_priority bg-medium");
    } else if (formik.values.task_priority === "L") {
      setPriorityClass("customInput tag_priority bg-grey");
    }
  }, [formik.values.task_priority]);

  const handlePriorityChange = (e) => {
    const selectedPriority = e.target.value;
    
    let className = "customInput tag_priority ";
    if (selectedPriority === "H") {
      className += "";
    } else if (selectedPriority === "M") {
      className += "bg-medium";
    } else if (selectedPriority === "L") {
      className += "bg-grey";
    }

    setPriorityClass(className);
    formik.handleChange(e);
  };

  useEffect(() => {
    if (textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, []);

  const style = {
    maxHeight: "400",
    minHeight: formik.values.task_title.length <= 30 ? "41px" : "82px",
    resize: "none",
    width: "100%", 
    scrollbarWidth: "none"
  };

  const handleDetailsBlur = () => {
    formik.setFieldTouched('task_details', true);
    formik.validateField('task_details');
  };

  console.log("formik",formik);

  const handleMemberChange = (e) => {
    const selectedMemberId = e.target.value;
    formik.handleChange(e); 
  
    const selectedMember = members.find((member) => member.member_id === parseInt(selectedMemberId));

    if (selectedMember) {
      setSelectedMemberImage(selectedMember.user_image);
    } else {
      setSelectedMemberImage(''); 
    }
  };
  

  return (
    <Modal
    show={showCard} 
    onHide={handleToggle} 
     className="task-create-modal"  backdrop="static">
     <Modal.Header closeButton>
        <div className="d-flex align-items-center">
        <h5 className="modal-title">Create Task</h5>
        </div>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>  
      <div className=" create_Tasks taskDetailModalBox">

             {/* { Left } */}
          <div className="taskdetailLeft">
                      <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="assignedCustom priorityCustom">
                              <div className="">
                              {/* { Add Priority } */}
                              <span className="custom_selectField">
                              <select
                                id="task_priority"
                                name="task_priority"
                                value={formik.values.task_priority}
                                onChange={handlePriorityChange}
                                className={priorityClass}
                              >
                                <option value="H" className="tag_priority">High</option>
                                <option value="M" className="tag_priority bg-medium">Medium</option>
                                <option value="L" className="tag_priority bg-grey">Low</option>
                              </select>
                                </span>
                             
                              </div>

                          </div>
                      </div>

                      <div className="clearfix"></div>

                      {/* { Title } */}
                    <div className="d-block w-100 mb-3 mt-2 ">
                        <textarea
                        className="taskdetails-title"
                        style={{ ...style, width: '100%' }} 
                        ref={textareaRef}
                        id="task_title"
                        name="task_title"
                        placeholder="Title"
                        value={formik.values.task_title}
                        onChange={(e) => {
                          const lines = e.target.value.split('\n');
                          if (e.target.value.length <= 100 && lines.length <= 1) { 
                            formik.handleChange(e);
                            formik.setFieldTouched('task_title', true, false);
                            e.target.style.height = 'auto'; 
                            e.target.style.height = `${e.target.scrollHeight}px`; 
                           
                          }
                        }}
                        onBlur={formik.handleBlur}
                        rows={1}
                      />
                      {formik.touched.task_title && formik.errors.task_title && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                          {formik.errors.task_title}
                        </div>
                )}
                       

                      </div>

                      <div className="createdbyInfo">
                            <img src={userData?.image} alt="" />
                            <div>
                            <span className="createdbythis">Created by {" "} {userData?.user_name}</span>
                            <div> </div>
                            </div>
                      </div>


                      <div className="boxesfullsBox">


                              {/* Streams Start*/}
                              <div className="boxesfulls">
                                  <label>Stream</label>
                                  <div className="assignedCustom">
                                        <div className="d-flex align-items-center">
                                           
                                        <select
                                            id="stream"
                                            name="stream"
                                            className={`customInput sidisabledselect ${formik.touched.stream && formik.errors.stream ? "is-invalid" : ""}`}
                                            value={formik.values.stream} 
                                            onChange={(e) => {
                                              formik.handleChange(e);
                                              const streamId = parseInt(e.target.value);
                                              fetchMembersByStreamId(streamId); 
                                            }}
                                            onBlur={formik.handleBlur}
                                            disabled={true} 
                                          >
                                            <option value={selectedLocalStream}>{streams.find(s => s.stream_id === selectedLocalStream)?.stream_name}</option>
                                          </select>

                                          {formik.touched.stream && formik.errors.stream ? (
                                            <div className="invalid-feedback">
                                              {formik.errors.stream}
                                            </div>
                                          ) : null}

                                        </div>

                                  </div>
                              </div>
                               {/* { Streams End } */}
                              

                              {/* Assigned To Start*/}
                              <div className="boxesfulls align-items-start assignederrormsg">
                                  <label className="mt-1">Assigned To<span className="required-asterisk">*</span></label>
                                  <div className="assignedCustom">
                                        <div className="d-flex align-items-center">
                                            <img 
                                              src={selectedMemberImage || profile}
                                              className="userimageLeft me-1"
                                              alt=""
                                            />
                                            <span className="custom_selectField flex-grow-1">
                                            <select
                                                  id="member"
                                                  name="member"
                                                  className={`customInput ${formik.touched.member && formik.errors.member
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                  value={formik.values.member}
                                                  onChange={handleMemberChange}
                                                  onBlur={formik.handleBlur}
                                              
                                                >
                                                  <option value="">Select Member</option>
                                                  {members &&
                                                    members.map((member) => (
                                                      <option key={member.member_id} value={member.member_id}>
                                                        {member.member_name}
                                                      </option>
                                                    ))}
                                            </select>
                                           
                                                
                                                 </span>

                                        </div>
                                        {formik.touched.member && formik.errors.member ? (
                                                  <div className="invalid-feedback"   style={{ display: "block" }}>
                                                    {formik.errors.member}
                                                  </div>
                                                ) : null}

                                  </div>
                              </div>
                               {/* { Assigned To End } */}

                              

                                {/* { Cost Start } */}
                               <div className="boxesfulls mt-4">
                                  <label>Cost (£)</label>
                                  <span className="costvalue">
                                  <input
                                      type="text"
                                      className={`customInput ${formik.touched.task_cost && formik.errors.task_cost ? "is-invalid" : ""}`}
                                      id="task_cost"
                                      name="task_cost"
                                      placeholder="Enter Task Cost"
                                      onChange={handleCostChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.task_cost}
                                    />
                                    {formik.touched.task_cost && formik.errors.task_cost && (
                                      <div className="invalid-feedback">
                                        {formik.errors.task_cost}
                                      </div>
                                    )}
                                  </span>
                               </div>
                                 {/* { Cost End } */}

                              {/* { Time Start } */}
                              <div className="boxesfulls taskTimehrs">
                                    <label>Time (In hours)</label>
                                    <input
                                      type="text"
                                      className={`customInput ${formik.touched.task_time && formik.errors.task_time
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                      id="task_time"
                                      name="task_time"
                                      placeholder="Enter Task Time"
                                      onChange={handleTimeChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.task_time}
                                    />

                                    {formik.touched.task_time && formik.errors.task_time && (
                                      <div className="invalid-feedback">
                                        {formik.errors.task_time}
                                      </div>
                                    )}
                              </div>
                                {/* { Time end } */}

                                {/* { ETA START } */}
                                <div className="boxesfulls duedatefield">
                                      <label>Due on<span className="required-asterisk">*</span></label>
                                      <input
                                          type="date"
                                          onKeyDown={handleKeyDown1}
                                          className={`customInput  ${formik.touched.task_eta && formik.errors.task_eta
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                          id="task_eta"
                                          name="task_eta"
                                          placeholder="ETA"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.task_eta}
                                          min={
                                            taskExistingData.estimation_Date
                                              ? taskExistingData.estimation_Date
                                              : getMinDate()
                                          }
                                        />
                                        {formik.touched.task_eta && formik.errors.task_eta && (
                                          <div className="invalid-feedback">
                                            {formik.errors.task_eta}
                                          </div>
                                        )}
                                </div>
                                {/* { ETA END } */}

                                  {/* { Tags start } */}
                                <div className="boxesfulls tagsblock align-items-start">
                                  <label className="pt-1">Tags</label>
                                    <div className="d-block flex-grow-1">
                                        <div className="tasktags">
                                          <ul> 
                                                {tags?.map((tag, index) => (
                                                  <li className="tag" key={index}>
                                                    <span className="text">{tag}</span>
                                                    <span
                                                      className="close"
                                                      onClick={() => removeTag(index)}
                                                    >
                                                      <img src={close} alt="close" />
                                                    </span>
                                                  </li>
                                                  
                                                ))}
                                                </ul>
                                                <input
                                                  type="text"
                                                  onKeyDown={handleKeyDown}
                                                  onBlur={handleTagsBlur}
                                                  onFocus={() => setIsInputFocused(true)}
                                                  className="tags-input customInput"
                                                  id="task_tags"
                                                  name="task_tags"
                                                  placeholder="Tags"
                                                />
                                               
                                        </div>
                                        {isInputFocused && (
                                          <div className="tags mt-1">* Press enter to add the tags</div>
                                        )}
                                    </div>
                                </div>
                                 {/* { Tags end } */}

                                 

                      </div>
       

          </div>

            {/* { Right } */}
            <div className="custom-tab modal-tabs createModalRight">
                      {/* <div className="tab-buttons">
                            <button
                                    // className={activeTab === "details" ? "active" : "inactive"}
                                    // onClick={() => handleTabClick("details")}
                                  >
                                    Details<span className="required-asterisk">*</span>
                                  </button>
                      </div> */}
                      <div class="boxesfulls mb-0"><label>Details<span class="required-asterisk">*</span></label></div>
                      {/* { Details } */}
                      <div className="tab-content">
                          <ReactQuill
                            value={formik.values.task_details}
                            onChange={(value, delta, source, editor) => {
                              handleEditorChange(value);
                            }}
                            theme="snow"
                            onBlur={handleDetailsBlur}
                            modules={{
                              toolbar: [
                                [{ size: [] }],
                                ['bold', 'italic', 'underline'],
                                ['link'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                              ],
                            }}
                          />

                            {formik.touched.task_details &&
                            formik.errors.task_details && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {formik.errors.task_details}
                              </div>
                            )}
                        {/* { Details end } */}

                        <div className="d-flex w-100 justify-content-end">
                                  <span className="btn btn-primary mt-3" onClick={()=>{
                                    console.log("save clicked")
                                    formik.handleSubmit();
                                  }}> Save</span> 
                                  </div>
                          
                      </div>
                     

            </div>

     </div>
     </form>
      <ToastContainer />
      {loader && <Loader />}

    </Modal>
  );
};
export default CreateTask;