import React, { useEffect, useState } from "react";
import TeamStreamChart from "./TeamStreamChart";
import UserStreamChart from "./UserStreamChart.js";
import axios from "axios";
import { API_URL } from "../../lib/network/api";
import Layout from "../layout/Layout";
import UserPriorityChart from "./UserPriorityChart";
import TeamPriorityChart from "./TeamPriorityChart";
import MembersTable from "./MembersTable";
import Loader from "../Loader/Loader";
import DetailsTable from "../Dashboard/DetailsTable"
const TeamDashboard = () => {
  const [priorityData, setPriorityData] = useState([]);
  const [priorityAllData, setPriorityAllData] = useState([]);
  const [streamAllData, setStreamAllData] = useState([]);
  const [streamData, setStreamData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  useEffect(() => {
    setLoader(true);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      if (window.innerWidth < 440 || window.innerHeight < 650) {
        window.location.reload();
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    setLoader(false);
    const userData = localStorage.getItem('loggedinUser')
    const { user_id } = JSON.parse(userData)
    axios
      .get(`${API_URL}graph/priorityAllData`)
      .then((response) => {
        setPriorityAllData(response?.data?.data || []);
      })
      .catch((error) => {
        console.error("Priority API Error:", error.message);
        setLoader(false)
      });
   
    axios
      .get(`${API_URL}graph/stream_all_data`)
      .then((response) => {
        setStreamAllData(response.data.data || []);
      })
      .catch((error) => {
      });
   
  }, []);
  const priorityLables = ['H', 'M', 'L'];
  const calculateTotalTasks = (tasks) => {
    const result = { H: 0, M: 0, L: 0 };
    tasks?.forEach((task) => {
      result.H += parseInt(task.H) || 0;
      result.M += parseInt(task.M) || 0;
      result.L += parseInt(task.L) || 0;
    });
    return result;
  };
  const priorityAllData_toDo = calculateTotalTasks(priorityAllData.to_do);
  const priorityAllData_inProgressTotal = calculateTotalTasks(priorityAllData.in_progress);
  const priorityData_toDo = calculateTotalTasks(priorityData.to_do);
  const priorityData_inProgressTotal = calculateTotalTasks(priorityData.in_progress);
  return (
    <Layout>
      <div className="container-fluid">
        <div className="row mt-4"><h4>Team Pending Tasks by priority</h4>
          <div className="col-lg-12">
            {/* <h6 style={{ textAlign: "center" }}>Pending Tasks by priority</h6> */}
            <TeamPriorityChart data={priorityAllData} lables={priorityLables} todos={priorityAllData_toDo} inProgress={priorityAllData_inProgressTotal} />
          </div>
        
          <div className="col-lg-12 my-4"><h4>Team Pending Task By Stream</h4>
            <br />
            {/* <h6 style={{ textAlign: "center" }}>Pending task by Stream</h6> */}
            {true ? (
              <TeamStreamChart data={streamAllData} />
            ) : (
              <p>No data available for Task By stream</p>
            )}
          </div>
        
       <DetailsTable/>
        </div>
      </div>
      {loader && <Loader />}
    </Layout>
  );
};
export default TeamDashboard;
