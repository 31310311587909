import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import EditIcon from '../../assets/images/edit-icon.svg';
import DeleteIcon from "../../assets/images/delete-icon.svg";
import AddBand from "../../assets/images/add-band.svg"
import AddCircleBtn from '../../assets/images/add-circle-btn.svg';
import axios from 'axios';
import { API_URL } from "../../lib/network/api";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Streams = () => {
  const [streams, setStreams] = useState([]);
  const [members, setMembers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [newStreamName, setNewStreamName] = useState('');
  const [newBandName, setNewBandName] = useState('');
  const [editingStream, setEditingStream] = useState(null);
  const [editingBand, setEditingBand] = useState(null);
  const [deletingStream, setDeletingStream] = useState(null);
  const [deletingBand, setDeletingBand] = useState(null);
  const [deletingMember, setDeletingMember] = useState(null);
  const [streamNameError, setStreamNameError] = useState('');
  const [bandNameError, setBandNameError] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [selectedStreamId, setSelectedStreamId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatClicked, setIsChatClicked] = useState(false);
  const [bands, setBands] = useState([]);
  const [activeStreamId, setActiveStreamId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);
  const userId = userData.user_id;
  useEffect(() => {
    fetchStreams();
    fetchMemberOptions();
  }, []);

  const fetchStreams = () => {
    axios.get(`${API_URL}task/stream`)
      .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          setStreams(response.data.data);
        } else {
          console.error('Unexpected response data format:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching streams:', error);
      });
  }
  const toTitleCase = str => {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };
  const fetchMemberOptions = () => {
    axios.get(`${API_URL}task/getAllUserNames`)
      .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          const options = response.data.data?.map(user => ({
            value: user.user_id,
            label: user.user_name
          }));
          setMemberOptions(options);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching member names:', error);
      });
  }

  const validateStreamName = (name) => {
    if (!name.trim()) {
      return 'Stream name is required';
    } else if (name.trim().length > 50) {
      return 'Stream name should not exceed 50 characters';
    }
    return '';
  };
  const handleAddStream = () => {

    const error = validateStreamName(newStreamName);
    if (error) {
      setStreamNameError(error);
      return;
    }
    const trimmedStreamName = newStreamName.trim();
    if (!trimmedStreamName) {
      setStreamNameError('Stream name cannot be empty');
      return;
    }
    if (trimmedStreamName.length > 50) {
      setStreamNameError('Stream name should not exceed 50 characters');
      return;
    }
    const newStream = { stream_name: trimmedStreamName, created_by: userId };
    setIsLoading(true);
    toast.dismiss();
    axios.post(`${API_URL}addStream`, newStream)
      .then(response => {
        console.log('API response:', response.data);
        if (response.data && response.data.code === 200) {
          toast.success("Stream added successfully")
          fetchStreams();
          setStreams([...streams, response.data.data]);
          setShowModal(false);
          setNewStreamName('');
          setStreamNameError('');
        }
        else {
          console.error('Unexpected response data:', response.data);
        }
      })
      .catch(error => {
        console.error('Error adding stream:', error);
        if (error.response) {
          if (error.response.status === 409) {
            toast.error("Stream with this name already exists");
          } else {
            toast.error("An error occurred while adding the stream");
          }
        } else {
          toast.error("Network error or server not reachable");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getStoredStreamName = () => {
    const storedStreamName = localStorage.getItem('selectedStreamName') || 'AU Projects Revival';
    localStorage.setItem('selectedStreamName', storedStreamName);
    return storedStreamName;
  };
  useEffect(() => {
    getStoredStreamName();
    console.log("")
  }, []);

  const handleEditStream = () => {
    const trimmedStreamName = newStreamName.trim();
    if (!trimmedStreamName) {
      setStreamNameError('Stream name cannot be empty');
      return;
    }
    if (trimmedStreamName.length > 50) {
      setStreamNameError('Stream name cannot exceed 50 characters');
      return;
    }
    const streamIdFromStorage = localStorage.getItem('selectedStreamId');
    const streamId = streamIdFromStorage ? parseInt(streamIdFromStorage) : selectedStreamId;
    const updatedStream = { stream_name: trimmedStreamName };
    setIsLoading(true);
    toast.dismiss()
    axios.put(`${API_URL}editStream?stream_id=${editingStream.stream_id}`, updatedStream)
      .then(response => {
        if (response.data && response.data.code === 200) {
          setStreams(streams?.map(stream =>
            stream.stream_id === editingStream.stream_id ? response.data.data : stream
          ));
          setShowModal(false);
          setNewStreamName('');
          fetchStreams();
          setEditingStream(null);
          setStreamNameError('');
          toast.success("Stream edited successfully")
        } else {
          console.error('Unexpected response data:', response.data);
        }
      })
      .catch(error => {
        // toast.error("Stream already exist")
        setShowModal(false);
        console.error('Error updating stream:', error);
        if (error.response) {
          if (error.response.status === 409) {
            toast.error("Stream with this name already exists");
          } else {
            toast.error("An error occurred while editing the stream");
          }
        } else {
          toast.error("Network error or server not reachable");
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  };
  const fetchBandsByStreamId = (streamId) => {
    axios.get(`${API_URL}getBandsByStream?stream_id=${streamId}`)
      .then(response => {
        if (response.data && response.data.code === 200) {
          const bandsData = response.data.data || [];
          localStorage.setItem("Bands", JSON.stringify(bandsData.map(band => band.band_name)) || [])
          setBands(bandsData);
          console.log("Bands fetched:", bandsData); // Debug log
        } else {
          console.error('Unexpected response data:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching bands:', error);
      });
  };
  const openEditModal = (stream) => {
    setEditingStream(stream);
    setNewStreamName(stream.stream_name);
    setShowModal(true);
    setModalType('edit');
  };
  const openBandEditModal = (band) => {
    setEditingBand(band);
    setNewBandName(band.band_name);
    setShowModal(true);
    setModalType('editBand');
  };

  const openDeleteStreamModal = (stream) => {
    setDeletingStream(stream);
    setShowModal(true);
    setModalType('deleteStream');
  };
  const openDeleteBandModal = (band) => {
    setDeletingBand(band);
    setShowModal(true);
    setModalType('deleteBand');
  };

  const openDeleteMemberModal = (member) => {
    setDeletingMember(member);
    console.log("member settings", member)
    setShowModal(true);
    setModalType('deleteMember');
  };

  const handleDeleteStream = () => {
    setIsLoading(true);
    axios.delete(`${API_URL}deleteStream?stream_id=${deletingStream.stream_id}`)
      .then(response => {
        if (response.data && response.data.code === 200) {
          setStreams(streams.filter(stream => stream.stream_id !== deletingStream.stream_id));
          setMembers([]);
          setBands([]);
          localStorage.removeItem("selectedStreamName");
          localStorage.removeItem("selectedStreamId");
          setSelectedStreamId(null);
          setShowModal(false);
          setDeletingStream(null);
          toast.success("Stream deleted successfully")
        } else {
          console.error('Unexpected response data:', response.data);
        }
      })
      .catch(error => {
        console.error('Error deleting stream:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditBand = async () => {
    if (!editingBand) return;
    setIsLoading(true);

    const error = validateBandName(newBandName);
    if (error) {
      setBandNameError(error);
      setIsLoading(false);
      return;
    }
  
    // Trim the newBandName before making the request
    const trimmedBandName = newBandName.trim();
  
    try {
      const response = await axios.put(`${API_URL}editDeleteBand`, {
        id: editingBand.id,
        stream_id: editingBand.stream_id,
        band_name: trimmedBandName,
        status: 0
      });
      if (response.status === 200) {
        setBands(prevBands =>
          prevBands.map(band =>
            band.id === editingBand.id ? { ...band, band_name: trimmedBandName } : band
          )
        );
        setShowModal(false);
        setEditingBand(null);
        setNewBandName('');
        setBandNameError('');
        toast.success('Band edited successfully');
      } else {
        console.error('Failed to edit band:', response);
      }
    } catch (error) {
      console.error('Error editing band:', error);
    }
    setIsLoading(false);
  };
  
  const handleDeleteBand = async () => {
    if (!deletingBand) return;
    setIsLoading(true);
    try {
      const response = await axios.put(`${API_URL}editDeleteBand`, {
        id: deletingBand.id,
        stream_id: deletingBand.stream_id,
        band_name: deletingBand.band_name,
        status: 1
      });
      if (response.status === 200) {
        toast.success('Band deleted successfully');
        setBands(prevBands => prevBands.filter(band => band.id !== deletingBand.id));
        setShowModal(false);
        setDeletingBand(null);
      } else {
        console.error('Failed to delete band:', response);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowModal(false)
        toast.error("This band has tasks hence cannot be deleted")
      } else {
        console.error('Error deleting band:', error);
        setErrorMessage('An error occurred while trying to delete the band.');
      }
    }
    setIsLoading(false);
  };

  const handleDeleteMember = () => {
    const streamId = localStorage.getItem('selectedStreamId');
    if (!streamId) {
      console.error('No streamId found in local storage.');
      return;
    }
    setIsLoading(true);
    axios.delete(`${API_URL}stream/delete-member`, {
      data: {
        stream_id: parseInt(streamId),
        member_id: deletingMember.member_id
      }
    })
      .then(response => {
        if (response.data && response.data.code === 200) {
          console.log('Member deleted successfully:', response.data.data.deleted_member);
          setMembers(members.filter(member => member.member_id !== deletingMember.member_id));
          setShowModal(false);
          setDeletingMember(null);
          toast.success("Member deleted successfully")
        } else {
          console.error('Failed to delete member:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error deleting member:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleMemberSelectChange = selectedOptions => {
    setSelectedMembers(selectedOptions);
  }
  const handleAddMembers = () => {
    const memberIds = selectedMembers?.map(member => member.value);
    const streamId = selectedStreamId;

    if (!streamId || memberIds.length === 0) {
      console.error('Stream ID or member IDs missing.');
      return;
    }

    const data = {
      stream_id: streamId,
      allowed_members: memberIds,
    };
    setIsLoading(true);
    axios.post(`${API_URL}stream/add-members`, data)
      .then(response => {
        if (response.data && response.data.code === 200) {
          if(response.data.message ===  "No new members to add"){
            setShowModal(false);
            setSelectedMembers([]);
            toast.success("Member already exists.")
          } else {
            console.log('Members added successfully:', response.data.data.allowed_members);
            setMembers(response.data.data.allowed_members);
            setShowModal(false);
            setSelectedMembers([]);
            toast.success("Member added successfully")
            fetchMembersByStreamId(streamId);
          }      
        } else {
          console.error('Failed to add members:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error adding members:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchMembersByStreamId = (streamId) => {
    axios.get(`${API_URL}getMembersByStreamId`, {params:{ stream_id: streamId} })
      .then(response => {
        if (response.data && response.data.code === 200) {
          setMembers(response.data.data.members);
        } else {
          console.error('Unexpected response data:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching members:', error);
      });
  };
const validateBandName = (name)=>{
  if (!name.trim()){
    return 'Band name is required';
  }else if (name.trim().length > 50) {
    return 'Band name should not exceed 50 characters';
  }
  return '';
}
  const handleAddBands = () => {
    const error = validateBandName(newBandName);
    if (error) {
      setBandNameError(error);
      return;
    }
    const trimmedBandName = newBandName.trim();
    if (!trimmedBandName) {
      setBandNameError('Band name cannot be empty');
      return;
    }
    if (trimmedBandName.length > 50) {
      setBandNameError('Band name should not exceed 50 characters');
      return;
    }
    const streamId = selectedStreamId;
    const newBand = { stream_id: streamId, band_name: trimmedBandName };
    setIsLoading(true);
    axios.post(`${API_URL}addBand`, newBand)
      .then(response => {
        if (response.data && response.data.code === 200) {
          fetchBandsByStreamId(streamId);
          setShowModal(false);
          setNewBandName('');
          toast.success("Band added successfully");
        } else {
          console.error('Unexpected response data:', response.data);
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setShowModal(false);
          toast.error("Band already exists with this name in this stream.");
        } else {
          console.error('Error adding band:', error);
        }
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const updatedBands = Array.from(bands);
    const [movedBand] = updatedBands.splice(result.source.index, 1);
    updatedBands.splice(result.destination.index, 0, movedBand);

    const reorderedBands = updatedBands.map((band, index) => ({
      ...band,
      display_order: index + 1,
    }));

    setBands(reorderedBands);
    const updatedBand = reorderedBands[result.destination.index];
    const apiData = {
      id: updatedBand.id,
      new_order: updatedBand.display_order,
      stream_id: updatedBand.stream_id,
    };

    try {
      const response = await axios.post(`${API_URL}updateBandOrder`, apiData);
      if (response.data.code === 200) {
        // toast.success('Band dragged successfully!');
        console.error("EEEEEEEEEEEEEEEEEE")
      } else {
        console.error('Failed to update band order:', response.data.message);
      }
    } catch (error) {
      console.error('Error updating band order:', error);
    }
  };
  const handleStreamClick = (streamId, streamName) => {

    // setIsChatClicked(false);
    setSelectedStreamId(streamId);
    // setActiveStreamId(null); 
    console.log("streamId", streamId)
    console.log("streamName", streamName)
    localStorage.setItem('selectedStreamId', streamId);
    localStorage.setItem('selectedStreamName', streamName);
    fetchMembersByStreamId(streamId);
    fetchBandsByStreamId(streamId);
  };
  // const handleChatClick = (stream, e) => {
  //   e.stopPropagation();
  //   setIsChatClicked(true); 
  //   setSelectedStreamId(stream.stream_id);
  //   setActiveStreamId(stream.stream_id); 
  //   fetchMembersByStreamId(stream.stream_id);
  //   fetchBandsByStreamId(stream.stream_id);
  // };
  useEffect(() => {
    if (selectedStreamId) {
      fetchBandsByStreamId(selectedStreamId);
    }
  }, [selectedStreamId]);
  return (
    <Layout>
      <div className="container-fluid">
        <div className="header-tp-action">
          <h4 className="mb-2">Streams List</h4>
        </div>
        <div className="whitebg_container create_Tasks">
          <div className="stream-container">
            <div className="stream-col">
              <div className="stream-heading">
                <h5>Streams</h5>
                <a href="#" onClick={() => { setShowModal(true); setModalType('add'); }} className="ms-auto">
                  <img src={AddCircleBtn} alt="Add Stream" className='add-circle-icon-wd'/>
                </a>
              </div>
              <ul className="stream-list">
                {streams?.map((stream) => (
                  <li
                    key={stream.stream_id}
                    onClick={() => handleStreamClick(stream.stream_id, stream.stream_name)}
                    className={selectedStreamId === stream.stream_id ? 'selected-stream' : ''}
                  >
                    <h6>{stream.stream_name}</h6>
                    <div className="stream-list-action">
                      <a href="#" onClick={() => openEditModal(stream)} className="ms-1">
                        <img src={EditIcon} alt="Edit" />
                      </a>
                      <a href="#" onClick={() => openDeleteStreamModal(stream)} className="ms-1">
                        <img src={DeleteIcon} alt="Delete" />
                      </a>
                      {/* <a href="#" onClick={(e) => handleChatClick(stream, e)} className="ms-1">
                        <img src={AddBand} alt="Chat" />
                      </a> */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className='stream-col'>
                  <div className='px-3'>
                    <div className="stream-heading">
                      <h5>Members List </h5>
                      <a href="#"
                        onClick={() => { setShowModal(true); setModalType('addMember'); }}
                        className="ms-auto"
                        style={{ pointerEvents: selectedStreamId ? 'auto' : 'none', opacity: selectedStreamId ? 1 : 0.5 }}>
                        <img src={AddCircleBtn} alt="Add Member" className='add-circle-icon-wd'/>
                      </a>
                    </div>
                    {members?.length > 0 ? (
                      <ul className="stream-member-list">
                        {members.map((member) => (
                          <li key={member.member_id}>
                            <h6>{member.member_name}</h6>
                            <div className="stream-list-action">
                              {member.member_id !== 46 && (
                                <a
                                  href="#"
                                  className={`ms-1 ${member.is_allow_delete === 0 ? 'disabled' : ''}`}
                                  onClick={(e) => {
                                    if (member.is_allow_delete === 0) {
                                      e.preventDefault(); 
                                    } else {
                                      openDeleteMemberModal(member);
                                    }
                                  }}
                                >
                                  <img src={DeleteIcon} alt="Delete" />
                                </a>
                                
                              )}
                               {member.is_allow_delete === 0 && (
                              <span className="tooltip">This member is the owner of one or more tasks. Hence it cannot be deleted.</span>
                               )}
                            </div>

                          </li>
                        ))}

                      </ul>
                    ) : (
                      <p style={{ textAlign: 'center', marginTop: '20px', color: '#999' }}>No members</p>
                    )}
                  </div>
                </div>
              
                {/* {isChatClicked && ( */}
                  <div className='stream-col'>
                    <div className='px-3 stream-last-col'>
                      <div className="stream-heading">
                        <h5>Bands List</h5>
                        <a href="#"
                          onClick={() => { setShowModal(true); setModalType('addband'); }}
                          className="ms-auto"
                          style={{ pointerEvents: selectedStreamId ? 'auto' : 'none', opacity: selectedStreamId ? 1 : 0.5 }}>
                          <img src={AddCircleBtn} alt="Add Band" className='add-circle-icon-wd'/>
                        </a>
                      </div>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="bands">
                          {(provided) => (
                            <ul className="stream-member-list" {...provided.droppableProps} ref={provided.innerRef}>
                              {bands.length > 0 ? (
                                bands.map((band, index) => (
                                  <Draggable key={band.id} draggableId={band.id.toString()} index={index}>
                                    {(provided) => (
                                      <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <h6>{band.band_name}</h6>
                                        <div className="stream-list-action">
                                          <a href="#" onClick={() => openBandEditModal(band)} className="ms-1">
                                            <img src={EditIcon} alt="Edit" />
                                          </a>
                                          <a href="#" onClick={() => openDeleteBandModal(band)} className="ms-1">
                                            <img src={DeleteIcon} alt="Delete" />
                                          </a>
                                        </div>
                                      </li>
                                    )}
                                  </Draggable>
                                ))
                              ) : (
                                <p style={{ textAlign: 'center', marginTop: '20px', color: '#999' }}>No bands</p>
                              )}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                 {/* )} */}

          </div>
        </div>
      </div>
      {showModal && (modalType == "edit" || modalType == "add") && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{modalType === 'add' ? 'Add Stream' : 'Edit Stream'}</h5>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="streamName" className="form-label"> <h6>Stream Name :</h6></label>
                  <input
                    type="text"
                    className="form-control"
                    id="streamName"
                    value={newStreamName}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        setNewStreamName(e.target.value);
                        if (streamNameError) setStreamNameError('');
                      }
                    }}
                  />
                  {streamNameError && <div className="text-danger">{streamNameError}</div>}
                </div>
              </div>
              <div className="modal-footer ">
                <button type="button" className="btn btn-secondary" onClick={() => { setShowModal(false); setNewStreamName(''); setEditingStream(null); setStreamNameError(''); }}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={modalType === 'add' ? handleAddStream : handleEditStream} disabled={isLoading}>
                  {modalType === 'add' ? 'Add Stream' : 'Edit Stream'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && modalType === 'deleteStream' && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Stream</h5>
              </div>
              <div className="modal-body">
                <div className='h-light-text text-center mt-4'>Are you sure you want to delete the stream "
                  <p className='word-break'>{deletingStream?.stream_name} "?</p>
                 </div>
              </div>
              <div className="modal-footer border-0 pt-0 justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={() => { setShowModal(false); setDeletingStream(null); }}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleDeleteStream} disabled={isLoading}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && modalType === 'addMember' && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Member</h5>
                {/* <button type="button" className="btn-close" onClick={() => { setShowModal(false); setSelectedMembers([]); }}></button> */}
              </div>
              <div className="modal-body">
                <label htmlFor="members" className="form-label"> <h6>Stream: {getStoredStreamName()}</h6></label>
                <div className="mb-3">
                  <h6 htmlFor="members" className="form-label">Members</h6>
                  <Select
                    id="members"
                    options={memberOptions}
                    isMulti
                    value={selectedMembers}
                    onChange={handleMemberSelectChange}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => { setShowModal(false); setSelectedMembers([]); }}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleAddMembers} disabled={isLoading} >
                  Add Members
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && modalType === 'deleteMember' && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Member</h5>
              </div>
              <div className="modal-body">
                {deletingMember && (
                  <p className='h-light-text text-center mt-4'>
                    {deletingMember.has_tasks > 0
                      ? `${deletingMember.member_name} has ${deletingMember.has_tasks} ${deletingMember.has_tasks > 1 ? "tasks" : "task"} in this stream. Are you sure you still want to delete ${deletingMember.member_name}?`
                      : `Are you sure you want to delete the member "${deletingMember.member_name}"?`}
                  </p>
                )}
              </div>
              <div className="stream-list-action">
                <a href="#" onClick={() => openDeleteMemberModal(members)} className="ms-1">
                </a>
              </div>
              <div className="modal-footer border-0 pt-0 justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={() => { setShowModal(false); setDeletingMember(null); }}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleDeleteMember} disabled={isLoading}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && (modalType == "editBand" || modalType == "addband") && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{modalType === 'addband' ? 'Add Band' : 'Edit Band'}</h5>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="bandName" className="form-label"> <h6>Band Name :</h6></label>
                  <input
                    type="text"
                    className="form-control"
                    id="bandName"
                    value={newBandName}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        setNewBandName(e.target.value);
                        if (bandNameError) {
                          setBandNameError('');
                          setIsLoading(false); 
                        }
                      }
                    }}
                  />
                  {bandNameError && <div className="text-danger">{bandNameError}</div>}
                </div>
              </div>
              <div className="modal-footer ">
                <button type="button" className="btn btn-secondary" onClick={() => { setShowModal(false); setNewBandName(''); setEditingBand(null); setBandNameError(''); }}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={modalType === 'addband' ? handleAddBands : handleEditBand} disabled={isLoading}>

                  {modalType === 'addband' ? 'Add Band' : 'Edit Band'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && modalType === 'deleteBand' && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Band</h5>
              </div>
              <div className="modal-body">
                <div className='h-light-text text-center mt-4'>Are you sure you want to delete the band <p className='word-break'>"{deletingBand?.band_name}"?</p></div>
              </div>
              <div className="modal-footer border-0 pt-0 justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={() => { setShowModal(false); setDeletingBand(null); }}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleDeleteBand} disabled={isLoading}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </Layout>
  );
};

export default Streams;
