import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from "formik";
import axios from 'axios';
import logo from "../../assets/images/login-logo-white.svg";
import { useNavigate, useLocation , Link} from 'react-router-dom';
import { API_URL } from '../../lib/network/api';
import Loader from '../../components/Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
});
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loginError, setLoginError] = useState(null);
  const [loader, setLoader] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  // const currentPath = location.pathname;

  useEffect(() => {
    let userData = localStorage.getItem("loggedinUser")
    const currentPath = location.pathname;
    if (userData) {
      navigate('/dashboard')
    }
  })
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoader(true)
      try {
        const response = await axios.post(`${API_URL}user/login`, {
          user_email: values.email,
          password: values.password,
        });
        if (response.status) {
          localStorage.setItem("loggedinUser", JSON.stringify(response?.data?.data?.user))
          localStorage.setItem("token", JSON.stringify(response?.data?.data?.token))
          navigate("/dashboard")
          setTimeout(() => {
            setLoader(false)
          }, 2000);
        }
      } catch (error) {
        setLoginError('Invalid email or password. Please try again.');
        setLoader(false)
      }
    },
  });
  useEffect(() => {
    setLoginError(null);
  }, [formik.values.email, formik.values.password]);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="login-cont">
          <div className="login-left-col">
            <img src={logo} className="img-fluid" alt="Login Logo" />
          </div>
          <div className="login-right-col">
            <div className="login-content-holder">
              <h4 className="mb-3">Login</h4>
              {loginError && (
                <div className="alert alert-danger" role="alert">
                  {loginError}
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="name@example.com"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error-message text-danger">{formik.errors.email}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div style={{ position: 'relative' }}>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder="**********"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  // value={formik.values.password}
                  />
                  {formik.values.password && (
                    <button
                      type="button"
                      className="eye-button"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ position: 'absolute', top: '50%', right: '5px', border: 'none', transform: 'translateY(-50%)', backgroundColor: 'white' }}
                    >
                      <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash}
                        className="eye-icon" />
                    </button>
                  )}
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className="error-message text-danger">{formik.errors.password}</div>
                )}
              </div>
              {/* <div style={{display:'flex',justifyContent:'flex-end',alignItems:'flex-end'}}>
              <p>Don't have an account?
                <Link to={'/register'} style={{ marginLeft: '5px' }}>
                  Register
                </Link>
              </p>
              </div> */}

                   <div style={{display:'flex',justifyContent:'flex-end',alignItems:'flex-end'}}>
          
                <Link to={'/forgot-password'} style={{ marginLeft: '5px' }}>
                 Forgot Password ?
                </Link>
         
              </div>
              <br/>
              <div className="mb-3">
                <button type="submit" className="btn btn-primary w-100 m-0">
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {loader && <Loader />}
    </div>
  );
};
export default Login;




