import React, { useEffect, useState } from "react";
import { getTimeLogData, postTimeLogData } from "../../lib/network/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useModal } from "../../lib/contexts/ModalContext";

const ActivityTable = ({ task_id, userId, setActivityList, activityList }) => {
  const { cardId, getActivityLog, loader } = useModal();
  const [isSaving, setIsSaving] = useState(false);

  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);
  const validationSchemaTimeLog = Yup.object({
    taskDescription: Yup.string()
      .required("Task description is required")
      .min(5, "Task description must contain at least 5 characters")
      .max(200, "Task description exceed 200 characters")
      .matches(/^[^\s][a-zA-Z0-9\s\-.,!"'?/()_$%&:;<>{}]*$/, {
        message: "Only alphanumeric are allowed",
      }),
      timeSpent: Yup.number()
      .required("Time spent is required")
      .min(0.01, "Invalid time format. Please use decimal format")
      .max(8, "Time spent cannot exceed 8 hours")
      .test('is-decimal', 'Invalid time format. Please use decimal format', value => 
        /^\d+(\.\d{1,2})?$/.test(value)
      )
      .test('max-decimal', 'Time spent cannot exceed 8.00 hours', value => 
        value <= 8.00
      ),
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleTimeSpentChange = (e) => {
    const value = e.target.value;
    console.log("value",value);
    if (value === "") {
      formikTimeLog.setFieldValue("timeSpent", "");
      formikTimeLog.setFieldTouched("timeSpent", true, false);
      return;
    }
    const formattedValue = value.match(/^\d+(\.\d{0,2})?$/);
    console.log("formattedValue",formattedValue);
    if (!isNaN(value) && value >= 0 && formattedValue) {
      formikTimeLog.setFieldValue("timeSpent", formattedValue[0]);
      formikTimeLog.setFieldTouched("timeSpent", true, false);
    }
  };




  const formikTimeLog = useFormik({
    initialValues: {
      taskDescription: "",
      timeSpent: "",
    },
    validationSchema: validationSchemaTimeLog,
    onSubmit: async (values) => {
      const trimmedTaskDescription = values.taskDescription.trim();
      setIsSaving(true)
      try {
        const data = {
          task_id,
          time_logged: parseFloat(values.timeSpent).toFixed(2),
          activity: trimmedTaskDescription,
          user_id: userId,
        };
        await postTimeLogData(data);
        getActivityLog(cardId);
        setIsSaving(false)
        formikTimeLog.resetForm();
      } catch (error) {
      }
    },
  });
  return (
    <form onSubmit={formikTimeLog.handleSubmit} className="">
<div className="timesheetOuter">
      <div className="timesheetForm mb-3">
        <div className="row">

          <div className="col-lg-12 mb-2">
            <label className="form-label text-start w-100">What you did for this task?</label>

            <textarea
              className={`form-control ${formikTimeLog.touched.taskDescription &&
                formikTimeLog.errors.taskDescription
                ? "is-invalid"
                : ""
                }`}
              name="taskDescription"
              value={formikTimeLog.values.taskDescription}
              onChange={formikTimeLog.handleChange}
              onBlur={formikTimeLog.handleBlur}
              cols="30"
              rows="10"
              placeholder="Enter task here"
            ></textarea>
            {formikTimeLog.touched.taskDescription &&
              formikTimeLog.errors.taskDescription && (
                <div className="invalid-feedback">
                  {formikTimeLog.errors.taskDescription}
                </div>
              )}
          </div>
          <div className="col-lg-12">
            <label className="form-label text-start w-100">How much time spent</label>
            <div className="d-flex align-items-start addtimefield">
              <div style={{ flex: "1" }}>
                <input
                  type="text"
                  className={`form-control ${formikTimeLog.touched.timeSpent &&
                    formikTimeLog.errors.timeSpent
                    ? "is-invalid"
                    : ""
                    }`}
                  id="timeSpent"
                  name="timeSpent"
                  value={formikTimeLog.values.timeSpent}
                  onChange={handleTimeSpentChange}
                  onBlur={formikTimeLog.handleBlur}
                placeholder="Use '0.5' for half an hour"
                />
                {formikTimeLog.touched.timeSpent &&
                  formikTimeLog.errors.timeSpent && (
                    <div className="invalid-feedback">
                      {formikTimeLog.errors.timeSpent}
                    </div>
                  )}
              </div>
              <button
                className="btn btn-primary ms-2"
                type="submit"
                disabled={!formikTimeLog.isValid || isSaving}
              >
                Save
              </button>

            </div>
          </div>
        </div>
      </div>
                 {activityList.timeLogs  ? (

                <div className="timesheetInfos overlayinfos">
                  <div className="timesheetInfos-header">
                    <h4 className="mb-0 mt-0">Timesheet Details</h4>
                    <h5 className="mb-0 mt-0">Total {activityList.totalTime} Hrs</h5>
                  </div>
                  <div className="timesheetInfos-content">
                    {activityList.timeLogs.map((activity, index) => (
                      <div className="timesheetRow" key={index}>
                        <img 
                          // src={activity.user_info.profile_picture || "default-image-url"} 
                          src={userData?.image}
                          className="timesheetAuthorImg" 
                          alt={activity.user_info.user_name}
                        />
                        <div className="timesheetRight">
                          <div className="authorInfoTop">
                            <strong>{activity.user_info.user_name}</strong> 
                            <span>{formatDate(activity.createdAt)}</span> 
                            <h5 className="ms-auto me-0">{activity.time_logged}</h5>
                          </div>
                          <p>{activity.activity}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                ) : (
                <div className="text-center timesheetnoFound">No Timesheet Found</div>
                )}
                </div>
    </form>
  );
};

export default ActivityTable;
