import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from "../../lib/network/api";
import { useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader/Loader";
import Select from "react-select";
import { useFormik } from 'formik';
import axios from "axios";

const Archive = () => {
  const [members, setMembers] = useState([]);
  const [streams, setStreams] = useState([]);
  const [selectedMember, setSelectedMember] = useState('');
  const [tasks, setTasks] = useState([]);
  const [isAll, setIsAll] = useState(localStorage.getItem('isAllTaskChecked') === 'true');
  const [loader, setLoader] = useState(false);
  const initialStream = parseInt(localStorage.getItem('selectedStream')) || 'all';
  const [selectedStream, setSelectedStream] = useState(initialStream);

  const navigate = useNavigate();

  // Parse loggedinUser from localStorage
  const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));
  const userId = loggedinUser.user_id;
  const userRole = loggedinUser.role;
  const isAllowedRole = ["PM", "TL", "TM"].includes(userRole);

  const formik = useFormik({ initialValues: { stream: initialStream } });

  useEffect(() => {
    getStreamsInArchive();
    fetchMembers();
  }, []);

  useEffect(() => {
    fetchData();
  }, [isAll, selectedStream, selectedMember]);


  const myTaskAttr = () => {
    const isAllTaskChecked = localStorage.getItem("isAllTaskChecked") === "true";
    return !isAllTaskChecked;
  }

  const getStreamsInArchive = () => {
    const myTask = myTaskAttr();
    axios.get(`${API_URL}task/stream?userId=${userId}&myTask=true`)
      .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          setStreams(response.data.data);
        } else {
          console.error('Unexpected response data format:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching streams:', error);
      });
  }
  const handleStreamChange = (selectedOption) => {
    const selectedStreamId = selectedOption?.value;
    localStorage.setItem("selectedStream", selectedStreamId === 'all' ? '' : selectedStreamId);
    setSelectedStream(selectedStreamId);
    formik.setFieldValue('stream', selectedStreamId);
  };
  
  const handleTaskDetails = (task) => {
    const { task_id } = task;
    navigate(`/archive-task-details?id=${task_id}`);
  };
const ArchiveMember = localStorage.getItem("selectedMember")
const streamId= localStorage.getItem("selectedStreamId");
  const fetchMembers = () => {
    axios.get(`${API_URL}task/getMembersByStreamId`,{ params: {
      stream_id:streamId
     }})
      .then(response => {
        console.log("response of fetch members", response);
        if (response.data && Array.isArray(response.data.data)) {
          setMembers(response.data.data);
          setSelectedMember( ArchiveMember || loggedinUser.user_id ); // Updated to set user_id instead of user_name
          console.log("1111111111", response.data.data)
        } else {
          console.error('Unexpected response data format:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching members:', error);
      });
  }
  const handleMemberChange = (selectedOption) => {
    const selectedMemberId = selectedOption?.value;
    setIsAll(selectedMemberId === 'All');
    setSelectedMember(selectedMemberId === 'All' ? '' : selectedMemberId);
    formik.setFieldValue('member', selectedMemberId);
  };
  const fetchData = async () => {
    const streamQueryParam = selectedStream && selectedStream !== 'all' ? `&stream=${selectedStream}` : '';
    const memberQueryParam = selectedMember ? `&member=${selectedMember}` : '';
    setLoader(true);
    try {
      const response = await fetch(`${API_URL}task/get2WeeksOldTasks?userId=${userId}&alltask=${isAll}${streamQueryParam}${memberQueryParam}`);
      const data = await response.json();
      setTasks(data.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoader(false);
    }
  };

  const removeHtmlTags = (value) => {
    if (!value) return '';
    return value.replace(/<[^>]*>/g, "");
  };

  return (
    <Layout>
      <div className="container-fluid">
        <div className="archive-headingr-row">
          <h4 className="mb-0">Archived Task</h4>
          <div className='ms-auto d-flex archive-filter-col'>
          <div className="d-flex align-items-center me-3" >
            <label htmlFor="stream" className="form-label mb-0 me-2">
              Stream
            </label>
            <Select
              className='status-cus-select arc-stream-input-wd'
              name="stream"
              value={{ value: formik.values.stream, label: formik.values.stream === 'all' ? 'All Streams' : streams.find(stream => stream.stream_id === formik.values.stream)?.stream_name }}
              onChange={handleStreamChange}
              options={[
                { value: 'all', label: 'All Streams' },
                ...streams.map(stream => ({ value: stream.stream_id, label: stream.stream_name }))
              ]}
              isSearchable={true}
              placeholder="Search or select stream"
            />
            {formik.touched.stream && formik.errors.stream && (
              <div className="invalid-feedback">
                {formik.errors.stream}
              </div>
            )}
          </div>
          { isAllowedRole && (
            <div className="d-flex align-items-center" >
            <label htmlFor="member" className="form-label mb-0 me-2">
              Member
            </label>
            <select
            className='form-select form-select-sm'
              value={selectedMember}
              onChange={(e) => handleMemberChange({ value: e.target.value })} // Updated to call handleMemberChange with member_id
            >
              <option value="All">All</option>
              {members.map(member => (
                <option key={member.user_id} value={member.user_id}> {/* Updated value to be member.user_id */}
                  {member.user_name}
                </option>
              ))}
            </select>
            {formik.touched.member && formik.errors.member && (
              <div className="invalid-feedback">
                {formik.errors.member}
              </div>
            )}
          </div>
          )}
          </div>
         
          </div>

        <div className="table-responsive mt-1">
          <table className="table archive-table">
            <thead>
              <tr>
                <th scope="col" style={{width:"34px"}}>Task ID</th>
                <th scope="col">Title</th>
                <th scope="col">Stream Name</th>
                <th scope="col">Details</th>
                <th scope="col">Assigned To</th>
                <th scope="col" style={{width:"34px"}}>Priority</th>
                <th scope="col" style={{width:"34px"}}>Days Since Update</th>
                <th scope="col" style={{width:"34px"}}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(tasks) ? tasks.map(task => (
                <tr key={task.task_id}>
                  <td>{task.task_id}</td>
                  <td>{task.task_title}</td>
                  <td>{task.stream_name}</td>
                  <td>{removeHtmlTags(task.task_details?.length > 60 ? task.task_details.substring(0, 100) + '...' : task.task_details)}</td>
                  <td>{task.task_assigned_to}</td>
                  <td>{task.task_priority}</td>
                  <td>{task.task_update_days}</td>
                  <td>
                    <button type="button" className="btn btn-link" onClick={() => handleTaskDetails(task)}>
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  </td>
                </tr>
              )) :
                <tr><td colSpan="8" style={{ textAlign: 'center' }}>No Data Found</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      {loader && <Loader />}
    </Layout>
  );
};

export default Archive;
