import React from "react";
import { useModal } from "../../lib/contexts/ModalContext";

const CustomModal = ({ isOpen, onClose, title, children, footerContent }) => {
  const { handleToggalModalState } = useModal();
  if (!isOpen) {
    return null;
  }
  return (
    <div className="custom-modal-overlay" onClick={handleToggalModalState}>
      {children}
    </div>
  );
};

export default CustomModal;
