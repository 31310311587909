import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    CartesianGrid,
    ResponsiveContainer,
    Label
} from 'recharts';

const StreamReport = ({ data }) => {
 
    const processedData = (data) => {
        return data.map((item) => {
            const streamName = Object.keys(item)[0];
            const priorities = item[streamName];
            return {
                name: streamName,
                H: priorities.H || 0,
                M: priorities.M || 0,
                L: priorities.L || 0,
            };
        });
    };

    const streams = data.length;
    const processStreamReportData = processedData(data);
    const chartWidth = streams > 8 ? Math.max(processStreamReportData.length * 100, window.innerWidth) : "100%" ;

    return (
        <div className="chart-container" style={{ width: '100%', height: '500px'  ,overflowX: 'auto' }}>
             <ResponsiveContainer width={chartWidth} height="100%">
                <BarChart
                   
                    data={processStreamReportData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                    dataKey="name" 
                    angle={-40} 
                    textAnchor="end" 
                    interval={0} 
                    height={150}
                    style={{ fontSize: '12px'}}
                >
                    <Label 
                        value="Streams" 
                        position="insideBottom" 
                        offset={-10} 
                        style={{ fill: '#808080', fontSize: '16px', fontWeight: 'bold' }}
                    />
                </XAxis>
                <YAxis>
                    <Label 
                        value="Tasks" 
                        angle={-90} 
                        position="insideLeft" 
                        style={{ fill: '#808080', fontSize: '16px', fontWeight: 'bold' }}
                    />
                </YAxis>
                <Tooltip />
                <Legend />
                <Bar dataKey="H" fill="#e57c25" name="High" barSize={20}  />
                <Bar dataKey="M" fill="#659cd7" name="Medium" barSize={20} />
                <Bar dataKey="L" fill="#a5a5a5" name="Low"  barSize={20} />
            </BarChart>
            </ResponsiveContainer>
        
        </div>
    );
};

export default StreamReport;
