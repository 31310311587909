import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import { useNavigate } from 'react-router-dom';
import { TagCloud } from 'react-tagcloud';
import { API_URL } from "../../lib/network/api";
import { useMainContext } from '../../lib/contexts/context';
import TaskDetailsPage from '../MyTasks/TaskDetailsPage';

const TagsCloud = () => {
  const navigate = useNavigate()
  const [tags, setTags] = useState([]);
  const {tagClicked,handleClickedTag} = useMainContext()
   // State to track selected tag
  const [cards, setCards] = useState([]); // State to store all cards
  const [filteredCards, setFilteredCards] = useState([]); // State to store filtered cards

  useEffect(() => {
    const fetchTagsAndCards = async () => {
      try {
        // Fetch tags
        const responseTags = await fetch(`${API_URL}task/tags_list_count`);
        const dataTags = await responseTags.json();
        const taskTags = dataTags.data.taskTags || [];
        setTags(taskTags.map(tagData => ({ value: tagData.task_tags, count: tagData.count })));

        // Fetch cards
        // const responseCards = await fetch(`${API_URL}cards`);
        // const dataCards = await responseCards.json();
        // setCards(dataCards); 
      } catch (error) {
        console.error('Error fetching tags or cards:', error);
      }
    };
    fetchTagsAndCards();
  }, []);

  // Function to handle tag click
  const handleTagClick = (tag) => {
    handleClickedTag(tag.value)
    navigate(`/my-tasks?tag=${tag.value}`)
    console.log("tag.value" , tag.value)
    // localStorage.setItem("selectedTag" , tag.value)

  };

  // Function to filter cards based on selected tag
  const filterCards = (tag) => {
    if (tag) {
      // Filter cards based on the selected tag
      const filtered = cards.filter(card => card.tags.includes(tag.value));
      setFilteredCards(filtered);
    } else {
      // If no tag is selected, reset filteredCards to all cards
      setFilteredCards(cards);

      
    }
  };

  return (

    <>
    {/* <TaskDetailsPage/> */}
    <Layout>
      <>
        <div className='container-fluid'>
          <div className="header-tp-action"><h4 className="mb-2">Tags Cloud :</h4></div>
          <div className='whitebg_container'>
            <div style={{ textAlign: 'center' }}>
              <TagCloud
                tags={tags}
                minSize={14}
                maxSize={45}
                shuffle={true}
                colorOptions={{
                  luminosity: 'dark',
                }}
                style={{
                  fontFamily: 'Arial, sans-serif',
                  fontSize: 20,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  margin: 'auto',
                }}
                onClick={handleTagClick} 
              />
            </div>
          </div>
        </div>

      </>
    </Layout>
    </>
  );
};

export default TagsCloud;
