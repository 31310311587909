import { createSlice } from "@reduxjs/toolkit";

const cardIdSlice = createSlice({
  name: "cardId",
  initialState: {
    id: null,
  },
  reducers: {
    setCardId: (state, action) => {
      console.log("state", state);
      console.log("action", action);
      state.id = action.payload;
    },
    clearCardId: () => null,
  },
});

export const { setCardId, clearCardId } = cardIdSlice.actions;
export default cardIdSlice.reducer;
