import React from "react";
import "../Loader/component.css";

const Loader = () => {
  return (
    <div className="loading">
      <div className="column">
        <div className="dot" id="dot1"></div>
        <div className="dot" id="dot2"></div>
        <div className="dot" id="dot3"></div>
      </div>
      <div className="column">
        <div className="dot" id="dot4"></div>
        <div className="dot center-dot" id="dot5"></div>
        <div className="dot" id="dot6"></div>
      </div>
      <div className="column">
        <div className="dot" id="dot7"></div>
        <div className="dot" id="dot8"></div>
        <div className="dot" id="dot9"></div>
      </div>
    </div>
  );
};

export default Loader;
