import React, {
  useEffect,
  useLayoutEffect,
  useState,
  CSSProperties,
  useRef
} from "react";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Layout from "../layout/Layout";
import {
  getTaskList,
  updateTaskStatusCycle,
  getStreamPriorityFilterDetails,
  API_URL,
  getStreamsData,
} from "../../lib/network/api";
import { useLocation, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TaskCard from "./TaskCard";
import { transformData } from "./TransformData";
import Loader from "../Loader/Loader";
import { useModal } from "../../lib/contexts/ModalContext";
import axios from "axios";
import { useMainContext } from "../../lib/contexts/context";
import { Link } from "react-router-dom";
import addBtn from "../../../src/assets/images/add-circle-btn.svg";
import TaskDetailsPage from "./TaskDetailsPage";

import { useSelector } from "react-redux";
import CreateTask from "../CreateTask/createTask";
import { useDispatch } from "react-redux";
import { toggleShowCard } from '../../redux/action/showCardSlice';
import { toggleCreateCard } from "../../redux/action/showCreateCardSlice";
import { MY_TASKS } from "../../constants/constant";

const MyTasks = () => {
  const { tagClicked, handleClickedTag } = useMainContext();
  const [selectedTag, setSelectedTag] = useState("");
  const [taskData, setTaskData] = useState(transformData([]));
  const [loader, setLoader] = useState(false);
  const [streams, setStreams] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [selectedStream, setSelectedStreams] = useState("");
  const [selectedPrioirty, setSelectedPrioirty] = useState("");
  let [listClassName, setClassName] = useState("hide");
  const dropdownRef = useRef(null);
  const [modalState, setToggalModalState] = useState(false);
  const [showCreateTaskComp, setCreateTaskComp] = useState(false);
  const [showTaskDetailComp, setTaskDetailComp] = useState(false);

  const {
    setUserId,
    userReportingTo,
    setUserReportingTo,
    userName,
    setUserName,
  } = useModal();
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isAllTaskChecked, setIsAllTaskChecked] = useState(
    localStorage.getItem("isAllTaskChecked") === "true"
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { streamName } = location.state || {};
  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);
  const userId = userData.user_id;
  // console.log("userData.user_id", userData.user_name);
  const userRole = userData?.role;
  const isAllowedRole = ["PM", "TL", "TM"].includes(userRole);
  const queryParams = new URLSearchParams(location.search);
  const selectedTaginTagsCloud = queryParams.get("tag");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showCreateCard = useSelector((state) => state.showCreateCard);
  const showCardDetail = useSelector((state) => state.showCard);
  const previousShowCardRef = useRef();

  const [isComponentVisible, setIsComponentVisible] = useState(true);

  // Function to toggle the state1
  const toggleComponent = () => {
    setIsComponentVisible((prevState) => !prevState);
  };

  useEffect(() => {
    if (streams.length > 0) {
      const firstStream = streams[0];
      handleListItemClick(firstStream);
      setClassName("show");
    }
  }, [streams]);

  const myTaskAttr = () => {
    const isAllTaskChecked =
      localStorage.getItem("isAllTaskChecked") === "true";
    return !isAllTaskChecked;
  };
  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };
  const bandsByStream = async (streamId) => {
    axios
      .get(`${API_URL}getBandsByStream?stream_id=${streamId}`)
      .then((response) => {
        // console.log("bands by stream response : ", response.data);
        if (response.data && response.data.code === 200) {
          const bandsData = response.data.data;
          localStorage.setItem(
            "Bands",
            JSON.stringify(bandsData.map((band) => band.id)) || []
          );
          localStorage.setItem(
            "BandList",
            JSON.stringify(
              bandsData.map((band) => ({
                currentBoard: toTitleCase(band.band_name),
                setBoardStatus: band.id,
              })) || []
            )
          );
          // console.log("Bands fetched:", bandsData); // Debug log
        } else {
          console.error("Unexpected response data:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching bands:", error);
      });
  };
  useEffect(() => {
    // Retrieve selected member and isAllTaskChecked from localStorage
    const savedMember = localStorage.getItem("selectedMember");
    const savedIsAllTaskChecked =
      localStorage.getItem("isAllTaskChecked") === "true";

    if (savedMember) {
      setSelectedMember(savedMember);
    } else {
      setSelectedMember("all");
    }
    setIsAllTaskChecked(savedIsAllTaskChecked);
  }, []);
  // useEffect(() => {
  //   const selectedStreamId = localStorage.getItem("selectedStream");
  //   const CreateStream = localStorage.getItem("CreateStream");
  //   // console.log("bands by stream called", selectedStreamId);
  //   bandsByStream(selectedStreamId || CreateStream);
  // }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (listClassName === 'show') {
        toggleList();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [listClassName]);

  function getCurrentBoard(setBoardStatus) {
    const boards = JSON.parse(localStorage.getItem("BandList")) || [];
    return (
      boards.find((board) => board.setBoardStatus === setBoardStatus)
        ?.currentBoard || null
    );
  }
  // console.log("selected mem", selectedMember);

  const setBoardData = async (data) => {
    console.log("board-data",data);
    
    if (data) {
      const myTask = myTaskAttr();
      // console.log("myTask :set board data ", myTask);
      // const stream_res = await getStreamsData(userId, myTask);
      // console.log("stream_res", stream_res);

      const prioirty_data = [
        { p: "High", p_id: "H" },
        { p: "Medium", p_id: "M" },
        { p: "Low", p_id: "L" },
      ];

      if (data.length > 0) {
        const transformedData = transformData(data);
        console.log("transformedData-data",transformedData);
        setTaskData(transformedData);
        // setStreams(stream_res.data.data);
        setPriorityList(prioirty_data);
      } else {
        const transformedData = transformData(data);
        setTaskData(transformedData);
        // setStreams(stream_res.data.data);
        setPriorityList(prioirty_data);
      }
    } else {
    }
  };
console.log("task-data-log",taskData);

  useEffect(() => {
    const fetchStreams = () => {
      setLoader(true);
      axios
        .get(`${API_URL}task/stream?userId=${userId}`, {})
        .then((response) => {
          console.log("response of stream in taskdashboard", response);
          if (response.data && Array.isArray(response.data.data)) {
            setStreams(response.data.data);
          } else {
            console.error("Unexpected response data format:", response.data);
          }
          setLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching streams:", error);
        });
    };
    fetchStreams();
  }, [userId]);

  useEffect(() => {
    const storedStream = localStorage.getItem("selectedStream");
    if (storedStream) {
      setSelectedStreams(Number(storedStream));
    }
    const storedPriority = localStorage.getItem("selectedPriority");
    if (storedPriority) {
      setSelectedPrioirty(storedPriority);
    }
  }, [location.pathname, selectedStream, selectedPrioirty]);

  // console.log("selectedPrioirty 161", selectedPrioirty);

  const handleMemberChange = async (selectedMemberId="") => {
    setSelectedMember(selectedMemberId);
    localStorage.setItem("selectedMember", selectedMemberId);
    setIsAllTaskChecked(false);

    const priorityLevel = priorityList.find(
      (priority) => priority.p === selectedPrioirty
    )?.p_id;
    const streamValue = selectedStream !== "" ? selectedStream : "";

    const params = {
      userId: userId,
      memberId: selectedMemberId === "all" ? "" : selectedMemberId,
      priorityFilter: priorityLevel ? priorityLevel : "",
      stream_id: streamValue ? streamValue : "",
      search: searchQuery || "",
    };

    // if (selectedMemberId !== "all") {
    //   params.myTask = true;
    // }

    getStreamPriorityFilterDetails(params)
      .then(async  (res) => {
        if (res.data != null) {
          await setBoardData(res.data.data);
        } else {
          await setBoardData([]);
        }
      })
      .catch(async (err) => {
        await setBoardData([]);
      });
  };

  // useEffect to check for the saved member selection in local storage on component mount

  const fetchMembers = async () => {
    try {
      const stream_id = localStorage.getItem("selectedStream");
      if (stream_id === MY_TASKS) {
        setMembers([{ member_id: userData?.user_id, member_name: userData?.user_name }]);
      } else {
      const response = await axios.get(`${API_URL}getMembersByStreamId`, {
        params: {
          stream_id,
        },
      });
      if (response.data && response.data.data.members) {
        setMembers(response.data.data.members);
      }
    }
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    // fetchMembers();
    const userid = JSON.parse(localStorage.getItem("loggedinUser"));
    if (userid.user_id) {
      setUserId(userid.user_id);
      setUserName(userid.user_name);
      setUserReportingTo(userid.user_reporting_to);
    }
  }, []);

  useLayoutEffect(() => {
    // console.log("get my task list called from layout effect Number 1");
    // getMyTaskList();
  }, [userId]);

  const getMyTaskList = async (tag) => {
    const prioirty_data = [
      { p: "High", p_id: "H" },
      { p: "Medium", p_id: "M" },
      { p: "Low", p_id: "L" },
    ];

    setPriorityList(prioirty_data);
    const priorityLevel = prioirty_data.find(
      (priority) => priority.p === selectedPrioirty
    )?.p_id;
    const savedMemberId = localStorage.getItem("selectedMember");
    const streamValue = selectedStream !== "" ? selectedStream : "";
    let params;
    params = {
      userId: userId,
      priorityFilter: priorityLevel ? priorityLevel : "",
      stream_id: streamValue ? streamValue : "",
      memberId: savedMemberId === "all" ? "" : savedMemberId,
      search: searchQuery || "",
    };

    if (tagClicked != null) {
      params.tag = tagClicked;
    }
    // console.log("saved member id", savedMemberId);
    // if (savedMemberId !== "all") {
    //   params.myTask = true;
    // }
    const storedStream = localStorage.getItem("selectedStream");
    if (storedStream) {
      params.stream_id = Number(storedStream);
    }
    const storedMember = localStorage.getItem("selectedMember");
    if (storedMember) {
      params.memberId = storedMember === "all" ? null : storedMember;
    }
    let storedPriority = localStorage.getItem("selectedPriority");
    if (storedPriority) {
      storedPriority = prioirty_data.find(
        (priority) => priority.p === storedPriority
      )?.p_id;
      params.priorityFilter = storedPriority;
    }
    // console.log("params and date", params, new Date().toISOString());
    // setTimeout(() => {
      getTaskList(params)
        .then(async (res) => {
          await setBoardData(res.data.data);
          // console.log("\n\n\nget tasks response \n\n", res.data.data);
        })
        .catch(async(err) => {
          if (err.response && err.response.status === 404) {
            await setBoardData([]);
          } else {
            console.error("Error fetching tasks:", err);
            await setBoardData([]);
          }
        })
        .finally(() => {
          // setLoader(false);
        });
    // }, 0);
  };

  const mapDroppableIdToStatus = (task_current_status) => {
    // console.log("task_current_status : ", task_current_status);

    const BandList = JSON.parse(localStorage.getItem("BandList")) || [];
    console.log("bands list with id : *****", BandList);

    if (task_current_status) {
      // console.log("Statuss matched", {
      //   setBoardStatus: BandList[task_current_status].setBoardStatus,
      //   currentBoard: BandList[task_current_status].currentBoard,
      // });
      return {
        setBoardStatus: BandList[task_current_status].setBoardStatus,
        currentBoard: BandList[task_current_status].currentBoard,
      };
    } else {
      // console.log("Statuss not matched", {
      //   setBoardStatus: -1,
      //   currentBoard: "jjj",
      // });
      return {
        setBoardStatus: -1,
        currentBoard: "jjj",
      };
    }
  };

  const onDragEnd = async (result) => {
    // console.log("on drag resukt: ", result);
    if (!result.destination) return;
    const { source, destination } = result;
    await testFunction(source, destination);
    const priorityLevel = priorityList.find(
      (priority) => priority.p === selectedPrioirty
    )?.p_id;
    const savedMemberId = localStorage.getItem("selectedMember");
    // console.log("saved member id on drag", savedMemberId);
    const streamValue = selectedStream !== "" ? selectedStream : "";
    const params = {
      userId: userId,
      priorityFilter: priorityLevel ? priorityLevel : "",
      stream_id: streamValue ? streamValue : "",
      search: searchQuery || "",
    };
    if (selectedTag !== "") {
      params.tag = selectedTag;
    }
    // if (savedMemberId !== "all") {
    //   params.myTask = true;
    // }
    const storedMember = localStorage.getItem("selectedMember");
    if (storedMember) {
      params.memberId = storedMember == "all" ? null : storedMember;
    }
    // console.log("on drag end params", params);
    getStreamPriorityFilterDetails(params)
      .then(async (res) => {
        if (res.data != null) {
          await setBoardData(res.data.data);
        } else {
         await setBoardData([]);
        }
      })
      .catch(async(err) => {
       await setBoardData([]);
      });
  };

  const testFunction = async (s, d) => {
    // console.log("task Data test function: ", taskData);
    const updatedTaskData = [...taskData];
    const sourceList = Array.isArray(updatedTaskData[s.droppableId].items)
      ? updatedTaskData[s.droppableId].items
      : [];
    const [movedTask] = sourceList.splice(s.index, 1);
    const destinationIndex = updatedTaskData[d.droppableId].items.length;
    updatedTaskData[d.droppableId].items.splice(destinationIndex, 0, movedTask);
    setTaskData(updatedTaskData);
    // console.log("d.droppableId : ****** ", d.droppableId);
    const { currentBoard: state } = mapDroppableIdToStatus(d.droppableId);
    const { currentBoard, setBoardStatus } = mapDroppableIdToStatus(
      d.droppableId
    );
    const movedTaskData = {
      task_id: movedTask.task_id,
      task_current_status: currentBoard,
      task_from: movedTask.task_from,
      task_assigned_to: userId,
      task_changed_by: movedTask.task_owner,
      task_when_changed: movedTask.task_when_changed,
      band_id: setBoardStatus,
    };
    try {
      const response = await updateTaskStatusCycle(movedTaskData);
    } catch (error) {}
  };

  useEffect(() => {
    const userid = JSON.parse(localStorage.getItem("loggedinUser"));
    if (userid.user_id) {
      setUserId(userid.user_id);
      setUserName(userid.user_name);
      setUserReportingTo(userid.user_reporting_to);
    }
  }, []);

  useEffect(() => {
    if (!showCreateCard) {
      setCreateTaskComp(false);
    }
  }, [showCreateCard]);

  useEffect(() => {
  const previousShowCard = previousShowCardRef.current;

  if (previousShowCard !== undefined && previousShowCard == true) {
    console.log('Previous showCardDetail:', previousShowCard);
    console.log('Current showCardDetail:', showCardDetail);
    getMyTaskList();
  }
  previousShowCardRef.current = showCardDetail;
    if (!showCardDetail) {
      setTaskDetailComp(false);
    }
  }, [showCardDetail]);

  // useLayoutEffect(() => {
  //   console.log("called second time layout effect get");
  //   getMyTaskList();
  // }, [userId]);

  const dispatch = useDispatch();
  const handleAddTask = (e) => {
    e.preventDefault();
    dispatch(toggleCreateCard());
    setCreateTaskComp(true);
    // navigate("/task", {
    //   state: { selectedPrioirty, selectedStream },
    // });
  };

  const handleTaskDetail = (e) => {
    e.preventDefault();
    setTaskDetailComp(true);
  };

  const handleSearch = async (query) => {
    const savedMemberId = localStorage.getItem("selectedMember");
    const priorityLevel = priorityList.find(
      (priority) => priority.p === selectedPrioirty
    )?.p_id;
    let params = {
      userId: userId,
      search: query || "",
      memberId: savedMemberId === "all" ? "" : savedMemberId,
      // priorityFilter: selectedPrioirty ? selectedPrioirty : "",
      priorityFilter: priorityLevel ? priorityLevel : "",
      stream_id: selectedStream ? selectedStream : "",
      tag: tagClicked ? tagClicked : "",
    };
    // if (selectedMember !== "all") {
    //   params.myTask = true;
    // }
    // console.log("handleSearch params", params);
    // const storedMember = localStorage.getItem("selectedMember");
    // if (storedMember) {
    //   params.memberId = storedMember == "all" ? null : storedMember;
    // }
    getTaskList(params)
      .then(async (res) => {
        // console.log("res search api ****** ", res.data.data);
        await setBoardData(res.data.data);
      })
      .catch(async(err) => {
        if (err.response && err.response.status === 404) {
         await setBoardData([]);
        } else {
          console.error("Error fetching tasks:", err);
         await setBoardData([]);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const handlePrioirtChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedPrioirty(selectedValue);
    // console.log("setSelectedPrioirty", setSelectedPrioirty);
    // console.log("isAllTaskChecked", isAllTaskChecked);
    localStorage.setItem("selectedPriority", selectedValue);
    const priorityLevel = priorityList.find(
      (priority) => priority.p === selectedValue
    )?.p_id;
    const streamValue = selectedStream !== "" ? selectedStream : "";
    const savedMemberId = localStorage.getItem("selectedMember");
    // console.log(
    //   "selectedMember and saved memberid",
    //   selectedMember,
    //   savedMemberId
    // );

    const params = {
      userId: userId,
      priorityFilter: priorityLevel ? priorityLevel : "",
      stream_id: streamValue ? streamValue : "",
      memberId: selectedMember == "all" ? null : selectedMember,
      search: searchQuery || "",
    };
    if (selectedTag != "") {
      params.tag = selectedTag;
    }
    // if (savedMemberId !== "all") {
    //   params.myTask = true;
    // }
    getStreamPriorityFilterDetails(params)
      .then(async(res) => {
        if (res.data != null) {
         await setBoardData(res.data.data);
        } else {
         await setBoardData([]);
        }
      })
      .catch(async(err) => {
        await setBoardData([]);
      });
  };

  if (loader) {
    return <Loader />;
  }
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch(searchQuery);
    }
  };
  const selectedStreamDashboard = localStorage.getItem(
    "selectedStreamDashboard",
    streamName
  );
  const BandList = JSON.parse(localStorage.getItem("BandList")) || [];
  const firstBandTitle =
    BandList.length > 0 ? BandList[0].setBoardStatus : null;
  const lastBandTitle =
    BandList.length > 0 ? BandList[BandList.length - 1].setBoardStatus : null;

  const formattedArray = streams.map((stream) => {
    return { value: stream.stream_id, label: stream.stream_name };
  });

  const toggleList = () => {
    setClassName(prevClassName => (prevClassName === "show" ? "hide" : "show"));
  };



 
  const handleListItemClick = async (stream) => {
    setLoader(true);
    setSearchQuery("")
    setSelectedMember("")
    localStorage.removeItem("selectedMember")
    setSelectedPrioirty("")
    localStorage.removeItem("selectedPriority")

    try {
      let params= {
        userId:userId,
        stream_id: stream.stream_id, 
        priorityFilter: "",
        memberId: "",
        search: "",
     
      };
      getTaskList(params)
      .then(async (res) => {
        setTaskData([]);
        setTimeout(async() => { 
          await setBoardData(res.data.data);
        }, 500);
        setLoader(false);
        toggleList()
        console.log("res-handleListItemClick", res.data.data);
        setSelectedStreams(Number(stream.stream_id))
        bandsByStream(stream.stream_id);
        localStorage.setItem("selectedStream",stream.stream_id)
        localStorage.setItem("selectedStreamDashboard",stream.stream_name)
      })
      .catch(async(err) => {
        
        if (err.response && err.response.status === 404) {
        setTimeout(async() => { 
          await setBoardData([]);
        }, 500);
         setLoader(false);
         toggleList()
         setSelectedStreams(Number(stream.stream_id))
         bandsByStream(stream.stream_id);
         localStorage.setItem("selectedStream",stream.stream_id)
         localStorage.setItem("selectedStreamDashboard",stream.stream_name)
         fetchMembers();
        } else {
          setTimeout(async() => { 
            await setBoardData([]);
          }, 500);
          setLoader(false);
          toggleList()
          setSelectedStreams(Number(stream.stream_id))
          bandsByStream(stream.stream_id);
          localStorage.setItem("selectedStream",stream.stream_id)
          localStorage.setItem("selectedStreamDashboard",stream.stream_name)
        }
      })
      .finally(() => {
        setLoader(false);
        fetchMembers();
      });

     
      // Handle the response data here
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      {showCreateTaskComp && <CreateTask getAllTask = {getMyTaskList} />}
      {showTaskDetailComp && <TaskDetailsPage getAllTask = {getMyTaskList} />}
      {/* <CreateTask getAllTask = {getMyTaskList} /> */}
      {/* <TaskDetailsPage getAllTask = {getMyTaskList} /> */}
      <Layout>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="bgwhiteFull">
          {  streams.length === 0 ? 
            <div  
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
              textAlign: 'center',
            }}>
            Currently you don't have access in any stream.
           </div>
            : (
            <div>
            <div className="pageHeader">
              <div className="container-fluid">
                <div className="pageTitle-dropdown" ref={dropdownRef}>
                  <h1 className="pageTitleName selected-boardinTitle" onClick={toggleList}>
                    {selectedStreamDashboard}
                  </h1>
                  <div className="boardlists">
      
                  <ul className={listClassName === 'hide' ? 'listhide' : 'listshow'}>
                      {streams.map((stream,index) => (
                         <li 
                         key={index} 
                         onClick={() => handleListItemClick(stream)}
                       >
                         {stream.stream_name}
                       </li>
                      ))}
                     
                    </ul>
                  </div>
                </div>
                <br />
                <div className="d-flex align-items-center justify-content-between">
                  <button
                    onClick={handleAddTask}
                    className="addtaskbtn"
                    type="button"
                  >
                    Add Task
                  </button>

                  <div className="header-tp-rt">
                    {/* <Link to="/archive-task" className="me-3 ">
                      View Archived Tasks
                    </Link> */}

                    <div className="search_box me-3">
                      <input
                        name="form-control"
                        placeholder="Search by keyword.."
                        value={searchQuery}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      {/* <button
                        className={`btn ${
                          !searchQuery ? "btn-border" : "btn-primary"
                        } ms-2`}
                        onClick={() => handleSearch(searchQuery)}
                      >
                        Search
                      </button> */}
                    </div>

                    <div className="filter_bar">
                      {isAllowedRole && (
                        <div className="filter_box me-3">
                          <label htmlFor="memberSelect"> Assigned to </label>
                          <select
                            id="memberSelect"
                            className="form-select"
                            aria-label="Select Member"
                            value={selectedMember}
                            onChange={(e) => handleMemberChange(e.target.value)}
                          >
                            {/* <option value="">
                              {isAllTaskChecked ? "All" : userData.user_name}
                            </option> */}
                            <option value="all">All</option>
                            {members?.map((member, index) => (
                              <option key={index} value={member.member_id}>
                                {member.member_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className="filter_box me-0">
                        <label htmlFor="prioritySelect">Priority </label>
                        <select
                          id="prioritySelect"
                          f
                          className="form-select"
                          aria-label="Priority Select"
                          value={selectedPrioirty}
                          onChange={(e) => handlePrioirtChange(e)}
                        >
                          <option value={""} selected>
                            All
                          </option>
                          {priorityList?.map((p, i) => {
                            return (
                              <option key={i} value={p.p}>
                                {p.p}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pageBody">
              <div className="container-fluid">
                <div className="create_Tasks">
                  <section className="task_section">
                    {loader && <Loader />}
                    {taskData?.map((section, sectionIndex) => {
                      const totalCost = section.items.reduce((acc, task) => {
                        const taskCost = task.task_cost
                          ? task.task_cost.replace(/,/g, "")
                          : "0";
                        return acc + (parseFloat(taskCost) || 0);
                      }, 0);
                      
                      const formattedTotalCost = Math.round(totalCost)

                      const totalTime = section.items.reduce((acc, task) => {
                        const taskTime = task.task_time
                          ? task.task_time.replace(/,/g, "")
                          : "0";
                        return acc + (parseFloat(taskTime) || 0);
                      }, 0);
                      const formattedTotalTime = Math.round(totalTime)
                      return (
                        <Droppable
                          key={sectionIndex}
                          droppableId={sectionIndex.toString()}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="task_list_whiteBg"
                              key={sectionIndex}
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              <div className="task_title d-flex align-items-start justify-content-between">
                                <div className="taskNamewithValue">
                                  <h2
                                    className="me-auto"
                                    title={getCurrentBoard(section.title)}
                                  >
                                    {getCurrentBoard(section.title)}
                                  </h2>

                                  {/* <div className="add-task-imgbtn">
                                  {" "}
                                  {section.title === firstBandTitle && (
                                    <img
                                      src={addBtn}
                                      alt="Add Task"
                                      className=""
                                      onClick={handleAddTask}
                                    />
                                  )}
                                </div> */}

                                  <div className="task_info">
                                    {/* {`${section.items.length > 1 ? "tasks" : "task"}`} */}
                                    <span className="totalCostInfo">{`£ ${formattedTotalCost}`}</span>
                                    <span className="totalhrsInfo">{`${formattedTotalTime} Hrs `}</span>
                                  </div>
                                </div>
                                <div className="tasklengthInfo">{`${section.items.length} `}</div>
                              </div>
                              {section.title === "Completed/ Approved" ? (
                                <Link to="/archive-task">
                                  View Archived Tasks
                                </Link>
                              ) : (
                                ""
                              )}
                              {/* {section.title === lastBandTitle && (
            <Link to="/archive-task" className="ms-2">
              View Archived Tasks
            </Link>
          )} */}
                              <div className="scroll_card" onClick={handleTaskDetail}>
                                {section.items.length === 0 ? (
                                 ""
                                ) : (
                                  section.items.map((task, taskIndex) => {
                                    return (
                                      <Draggable
                                        key={`${sectionIndex}-${taskIndex}`}
                                        draggableId={`${sectionIndex}-${taskIndex}`}
                                        index={taskIndex}
                                      >
                                        {(provided) => (
                                          <TaskCard
                                            key={taskIndex}
                                            task={task}
                                            sectionIndex={sectionIndex}
                                            taskIndex={taskIndex}
                                            provided={provided}
                                            userId={userId}
                                            userName={userName}
                                            userReportingTo={userReportingTo}
                                            getMyTaskList={() => {
                                              getMyTaskList();
                                            }}
                                            members = {members}
                                            modalState={modalState}
                                          />
                                        )}
                                      </Draggable>
                                    );
                                  })
                                )}
                              </div>
                            </div>
                          )}
                        </Droppable>
                      );
                    })}
                  </section>
                </div>
              </div>
            </div>
            </div>)
            }
          </div>
        </DragDropContext>
      </Layout>
    </>
  );
};

export default MyTasks;
