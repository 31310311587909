import React from "react";
import { Bar } from "react-chartjs-2";

function UserPriorityChart({ data }) {
  if (!data || !data.to_do || !Array.isArray(data.to_do)) {
    console.error("Invalid data format. Expected 'to_do' to be an array.");
    return <ChartWithData toDoData={{ H: 0, M: 0, L: 0 }} inProgressData={{ H: 0, M: 0, L: 0 }} />;
  }
  const toDoData = data.to_do[0] || {};
  const inProgressData = data.in_progress && data.in_progress[0] ? data.in_progress[0] : {};
  return <ChartWithData toDoData={toDoData} inProgressData={inProgressData} />;
}
function ChartWithData({ toDoData, inProgressData }) {
  // ************** start get chart highestValue *******
  const allValues = Object.values({ toDoData, inProgressData }).flatMap(category => {
    return Object.values(category).map(value => parseInt(value, 10) || 0);
  });

  // Find the highest value
  const highestValue = Math.max(...allValues);
  // ************** end get chart highestValue *******

  const toDoDataset = {
    label: 'To Do',
    data: [parseInt(toDoData.H) || 0, parseInt(toDoData.M) || 0, parseInt(toDoData.L) || 0],
    backgroundColor: ['rgba(255,99,132,1)'],
    borderColor: ['rgba(255,99,132,1)'],
    borderWidth: 1,
    barThickness: 50,
  };
  const inProgressDataset = {
    label: 'In Progress',
    data: [parseInt(inProgressData.H) || 0, parseInt(inProgressData.M) || 0, parseInt(inProgressData.L) || 0],
    backgroundColor: ['rgba(75,192,192,1)'],
    borderColor: ['rgba(75,192,192,1)'],
    borderWidth: 1,
    barThickness: 50,
  };
  const chartData = {
    labels: ['H', 'M', 'L'],
    datasets: [toDoDataset, inProgressDataset],
  };

  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: true,
        max: Math.round((highestValue + 200) / 2) * 2,
        stepSize: 10,
        barPercentage: 1,
      },
    },
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'end',
        align: 'top',
        formatter: (value, context) => {
          if (context.datasetIndex === context.chart.data.datasets.length - 1) {
            // Only show label for the last dataset (total count)
            let sum = 0;
            context.chart.data.datasets.forEach((dataset) => {
              sum += dataset.data[context.dataIndex];
            });
            return sum.toLocaleString();
          } else {
            return '';
          }
        },
        display: true,
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  );
}
export default UserPriorityChart;
