import React, { useState } from 'react';
import { useParams , useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL } from '../../lib/network/api';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import resetIcon from "../../assets/images/login-logo-white.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, 
        'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number'
      )
        .required('Password is required'),
        
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required')
    }),
    onSubmit: (values, { setSubmitting }) => {
      axios.post(`${API_URL}resetPassword/?token=${token}`, {
        password: values.password
      })
      .then(response => {
        // if (response.status === 200) {
        //   toast.success('Password reset successfully');
        //   setTimeout(() => {
        //     navigate('/');
        //   }, 2500);
        // }
        if (response.status === 202) {
          toast.warning("Old password and new password can not be same.");
        } else {
          toast.success(response.data.message);
          setTimeout(() => {
               navigate('/');
              }, 2500);
         
        }

      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          console.error('Error resetting password:', error);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
    }
    
  });

  return (
    <div className="login-cont">
      <div className="login-left-col">
        <img src={resetIcon} className="img-fluid" alt="Reset Icon"/>
      </div>
      <div className="login-right-col">
        <div className="login-content-holder">
          <h4 className="mb-3 text-start">Reset Password</h4>
          <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
              <label htmlFor="password" className="form-label">New Password:</label>
              <div className="input-group">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  className="form-control"
                  {...formik.getFieldProps('password')}
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="text-danger">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">Confirm Password:</label>
              <div className="input-group">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  className="form-control"
                  {...formik.getFieldProps('confirmPassword')}
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                </button>
              </div>
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div className="text-danger">{formik.errors.confirmPassword}</div>
              ) : null}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <Link to={'/'} style={{ marginLeft: '5px' }}>
                Back to Login
              </Link>
            </div>
            <div className="mb-3">
              <button type="submit" className="btn btn-primary w-100 m-0">
                Reset
              </button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
