import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import resetIcon from "../../assets/images/login-logo-white.svg";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from "../../lib/network/api";

const ForgotPassword = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
  onSubmit: async (values, { setSubmitting, setFieldError, resetForm }) => {
    try {
      const response = await axios.post(`${API_URL}forgotPassword`, { email: values.email });
    console.log("response", response)
      if (response.status === 200) {
        toast.success("Reset Email has been sent.");
        resetForm();
      } else {
        toast.error('Failed to send email');
      }
    } catch (error) {
      toast.error(error.response.data.message)
      console.error('Error:', error);
    } finally {
      setSubmitting(false);
    }
  },
});

  return (
    <div className="login-cont">
      <div className="login-left-col">
        <img src={resetIcon} className="img-fluid" alt="Reset Icon" />
      </div>
      <div className="login-right-col">
        <div className="login-content-holder">
          <h4 className="mb-3 text-start">Forgot Password</h4>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              {/* <label htmlFor="email" className="form-label">Enter Email Address</label> */}
              <input
                type="text"
                id="email"
                className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                {...formik.getFieldProps('email')}
                placeholder='Enter Email Address'
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback">{formik.errors.email}</div>
              ) : null}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <Link to={'/'} style={{ marginLeft: '5px' }}>
                Back to Login
              </Link>
            </div>
            <br />
            <div className="mb-3">
              <button type="submit" className="btn btn-primary w-100" disabled={formik.isSubmitting}>
                {formik.isSubmitting ? 'Sending...' : 'Send'}
              </button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
