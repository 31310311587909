import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Select from "react-select";
import { API_URL } from "../../lib/network/api";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import close from "../../assets/images/close.svg";
import profile from "../../assets/images/profile_image.png";
import autosize from "autosize";
import {
  getAllComments,
  getTaskDetails,
  getTimeLogData,
  postAComment,
  postTimeLogData,
  updateETADate,
  updateTaskStatus,
  updateTaskStatusCycle,
  deleteTask
} from "../../lib/network/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import editIcon from "../../assets/images/edit.svg";
import Loader from "../../components/Loader/Loader";
import { CommentList } from "./CommentList";
import { useSelector,useDispatch } from "react-redux";
import { toggleShowCard } from '../../redux/action/showCardSlice';
import { toggleShowCardEdit } from '../../redux/action/showCardEditSlice';
import { MY_TASKS } from "../../constants/constant";

const TaskDetailsPage = ({getAllTask}) => {
  const [tasksDetails, setTasksDetails] = useState([]);
  const [ETA, setETA] = useState();
  const [loader, setLoader] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [selectedTaggedMembers, setSelectedTaggedMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [existingMembers, setExistingMembers] = useState([]);
  // const [selectedStatus, setSelectedStatus] = useState("");
  const [bands, setBands] = useState([]);
  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);
  const userId = userData.user_id;
  const [isSaving, setIsSaving] = useState(false);
  const [originalCost, setOriginalCost] = useState("");
  const [originalTime, setOriginalTime] = useState("");
  const [originalMembers, setOriginalMembers] = useState([]);
  const [editedCost, setEditedCost] = useState("");
  const [editedTime, setEditedTime] = useState("");
  const [editedMembers, setEditedMembers] = useState([]);
  const userName = userData.user_name;
  const [selectedStatus, setSelectedStatus] = useState(() => {
    return localStorage.getItem("task_current_status") || "";
  });
  const userReportingTo = userData.user_reporting_to;
  const [activeTab, setActiveTab] = useState("details");

  const [originalTitle, setOriginalTitle] = useState("");
  const [editedTitle, setEditedTitle] = useState("");

  const [originalPriority, setOriginalPriority] = useState("");
  const [editedPriority, setEditedPriority] = useState("");

  const [originalAssignedTo, setOriginalAssignedTo] = useState();
  const [editedAssignedTo, setEditedAssignedTo] = useState();

  const [originalTags, setOriginalTags] = useState([]);
  const [editedTags, setEditedTags] = useState([]);

  const [originalDetails, setOriginalDetails] = useState("");
  const [editedDetails, setEditedDetails] = useState("");

  const [originalETA, setOriginalETA] = useState("");
  const [editedETA, setEditedETA] = useState("");

  const [members, setMembers] = useState([]);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [taskStatus , setTaskStatus] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [selectedMemberImage, setSelectedMemberImage] = useState('');
  
  const textareaRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const formik = useFormik({
    initialValues: {
      tagmembers: selectedTaggedMembers,
    },
  });


  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleTimeSpentChange = (e) => {
    const value = e.target.value;
    console.log("value", value);
    if (value === "") {
      formikTimeLog.setFieldValue("timeSpent", "");
      formikTimeLog.setFieldTouched("timeSpent", true, false);
      return;
    }
    const formattedValue = value.match(/^\d+(\.\d{0,2})?$/);
    console.log("formattedValue", formattedValue);
    if (!isNaN(value) && value >= 0 && formattedValue) {
      formikTimeLog.setFieldValue("timeSpent", formattedValue[0]);
      formikTimeLog.setFieldTouched("timeSpent", true, false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setExistingMembers(tasksDetails.allow_members_to_comment || []);
  }, [tasksDetails.allow_members_to_comment]);

  const handleTaggedMembersChange = (selectedMembers) => {
    formik.setFieldValue("tagmembers", selectedMembers);
    setSelectedMembers(selectedMembers);
    setTasksDetails((prevTasksDetails) => ({
      ...prevTasksDetails,
      allow_members_to_comment: selectedMembers.map((member) => ({
        id: member.value,
        name: member.label,
      })),
    }));
  };

  useEffect(() => {
    if (textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, []);

  const style = {
    maxHeight: "400",
    minHeight: editedTitle.length <= 30 ? "41px" : "82px",
    resize: "none",
    width: "100%", 
    scrollbarWidth: "none"
  };

  useEffect(() => {
    if (tasksDetails) {
      setOriginalCost(tasksDetails.task_cost ? tasksDetails.task_cost : "0");
      setOriginalTime(tasksDetails.task_time ? tasksDetails.task_time : "0");
      setOriginalMembers(
        tasksDetails?.allow_members_to_comment?.map((member) => ({
          label: member.name,
          value: member.id,
        }))
      );
      setEditedCost(tasksDetails.task_cost ? tasksDetails.task_cost : "0");
      setEditedTime(tasksDetails.task_time ? tasksDetails.task_time : "0");
      setEditedMembers(
        tasksDetails?.allow_members_to_comment?.map((member) => ({
          label: member.name,
          value: member.id,
        }))
      );
    }
  }, [tasksDetails]);

  useEffect(() => {
    if (tasksDetails) {
      setOriginalTitle(tasksDetails.task_title || "");
      setEditedTitle(tasksDetails.task_title || "");

      setOriginalPriority(tasksDetails.task_priority || "");
      setEditedPriority(tasksDetails.task_priority || "");

      setOriginalAssignedTo(tasksDetails.task_assigned_to || "");
      setEditedAssignedTo(tasksDetails.task_assigned_to || "");

      setOriginalTags(tasksDetails.task_tags || []);
      setEditedTags(tasksDetails.task_tags || []);

      setOriginalDetails(tasksDetails.task_details || "");
      setEditedDetails(tasksDetails.task_details || "");

      setOriginalETA(tasksDetails.estimation_Date || "");
      setEditedETA(tasksDetails.estimation_Date || "");

      setTaskStatus(tasksDetails.task_current_status)
    }
  }, [tasksDetails]);

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handlePriorityChange = (e) => {
    setEditedPriority(e.target.value);
  };

  const handleAssignedToChange = (selectedValue) => {
    setEditedAssignedTo(selectedValue);
  };



  const handleDetailsChange = (value) => {
    setEditedDetails(value);
  };

  const handleCostInputChange = (e) => {
    setEditedCost(e.target.value);
    handleCostChange(e);
  };

  const handleTimeInputChange = (e) => {
    setEditedTime(e.target.value);
    handleTimeChange(e);
  };
  const handleMembersChange = (selectedOptions) => {
    setEditedMembers(selectedOptions);
    handleTaggedMembersChange(selectedOptions);
  };
  let originalMemberIds = originalMembers
    ?.map((member) => member.value)
    .sort()
    .join(",");
  let editedMemberIds = editedMembers
    ?.map((member) => member.value)
    .sort()
    .join(",");

  const hasChanges = () => {
    return (
      editedCost !== originalCost ||
      editedTime !== originalTime ||
      editedTitle !== originalTitle ||
      editedDetails !== originalDetails ||
      editedPriority !== originalPriority ||
      editedAssignedTo !== originalAssignedTo ||
      editedTags !== originalTags ||
      editedETA !== originalETA ||
      originalMemberIds !== editedMemberIds 
    );
  };

  const handleSaveButtonClick = () => {
    if (!hasChanges()) return;
    if (!tasksDetails || !tasksDetails.task_assigned_to) {
      return;
    }
    const editedMemberIds = selectedMembers
      .filter((member) => member && member.id)
      .map((member) => member.id);
    const existingMemberIds = tasksDetails.allow_members_to_comment
      .filter((member) => member && member.id)
      .map((member) => member.id);
    const combinedMemberIds =
      editedMemberIds.length > 0
        ? [...existingMemberIds, ...editedMemberIds]
        : existingMemberIds;
    const payload = {
      id: tasksDetails.task_id,
      task_title: editedTitle || tasksDetails.task_title,
      task_owner: tasksDetails.task_owner,
      task_eta: tasksDetails.task_eta,
      eta_in_date_time: editedETA || tasksDetails.eta_in_date_time,
      task_priority: editedPriority || tasksDetails.task_priority,
      stream: tasksDetails.stream,
      task_details: editedDetails || tasksDetails.task_details,
      task_assigned_to: Number(editedAssignedTo) || tasksDetails.task_assigned_to,
      task_tags: editedTags || tasksDetails.task_tags,
      task_owner_id: tasksDetails.task_owner_id,
      task_cost: editedCost !== undefined ? editedCost : tasksDetails.task_cost,
      task_time: editedTime !== undefined ? editedTime : tasksDetails.task_time,
      allow_members_to_comment: combinedMemberIds,
    };
    setIsSaving(true);
    axios
      .put(`${API_URL}task/task_update`, payload)
      .then((response) => {
        setExistingMembers(selectedMembers);
        console.log("save btn data",response.data);
        // toast.success("Details updated successfully");
        setIsSaving(false);
        setOriginalTitle(editedTitle);
        setOriginalPriority(editedPriority);
        setOriginalAssignedTo(editedAssignedTo);
        setOriginalTags(editedTags);
        setOriginalDetails(editedDetails);
        setOriginalETA(editedETA);
        setOriginalCost(editedCost);
        setOriginalTime(editedTime);
        setOriginalMembers(editedMembers);
        getAllTask();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { code, message } = error.response.data;
            toast.error(message);
        } else {
          toast.error("An unexpected error occurred.");
        }
      });
  };


  // const taskId = useSelector(state => state.cardId)
  const taskId = localStorage.getItem("task_id")
  // console.log("taskId",taskId);
  // const taskId = queryParams.get("id");
  useEffect(() => {
    setLoader(true);
    if (taskId) {
      // fetchTaskDetails(taskId);
      getActivityLog(taskId);
      fetchComments();
      setLoader(true);
    } else {
      navigate("/my-tasks");
    }
  }, [taskId]);

  const fetchTaskDetails = (t_id) => {
    console.log("from fetch task details",t_id);
    const param = {
      task_id: t_id,
    };
    getTaskDetails(param)
      .then((res) => {
        setTasksDetails(res.data.data); 
        fetchMembersByStreamId(res.data.data.stream)
       setETA(res.data.data.estimation_Date);
      })
      .catch((err) => {
        setTasksDetails([]);
        setETA();
      })
      .finally(() => {});
  };

  const handleCostChange = (e) => {
    const { value, keyCode } = e.target;
    if (keyCode === 8) {
      setEditedCost("");
      return;
    }
    let newValue = value.replace(/[^0-9.,]/g, "");
    const [integerPart, decimalPart] = newValue.split(".");
    let formattedIntegerPart = integerPart.replace(/,/g, "").slice(0, 8);
    formattedIntegerPart = formattedIntegerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    newValue =
      decimalPart !== undefined
        ? `${formattedIntegerPart}.${decimalPart.slice(0, 2)}`
        : formattedIntegerPart;
    setEditedCost(newValue);
  };

  const handleTimeChange = (e) => {
    let value = e.target.value.replace(/[^0-9.,]/g, "");
    const [integerPart, decimalPart] = value.split(".");
    let formattedIntegerPart = integerPart.replace(/,/g, "").slice(0, 4);
    formattedIntegerPart = formattedIntegerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    value =
      decimalPart !== undefined
        ? `${formattedIntegerPart}.${decimalPart.slice(0, 2)}`
        : formattedIntegerPart;
    setEditedTime(value);
  };

  const handleKeyDownETA = (e) => {
    e.preventDefault();
  };

  const handleEtaChange = (value) => {
    setEditedETA(value)
  }

  const getMinDate = () => {
    const today = new Date();
    const year = today.getFullYear();

    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`;
  };

  const fetchReadOnlyETA = () => {
    var inputDateString = tasksDetails.estimation_Date;
    var inputDate = new Date(inputDateString);
    var day = inputDate.getDate();
    var month = inputDate.getMonth() + 1;
    var year = inputDate.getFullYear();
    var formattedDateETA =
      (day < 10 ? "0" : "") +
      day +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      year;
    return formattedDateETA;
  };

  const dispatch = useDispatch();

  const handleEditToggle = (e) => {
    console.log("clicked");
    e.preventDefault();
    dispatch(toggleShowCardEdit());

  };
  const getPriorityLabel = (priority) => {
    const priorityMap = { H: "High", M: "Medium", L: "Low" };
    return priorityMap[priority] || priority;
  };
  const getActivityLog = async (t_id) => {
    try {
      // setLoader(true);
      const params = {
        task_id: t_id,
      };
      const response = await getTimeLogData(params);
      if (response.data && response.data.data){
        setActivityList(response.data.data);
      } else {
        setActivityList([]);
      }
    } catch (error) {
      setActivityList([]);
    } finally {
      setLoader(false);
    }
  };
  const fetchComments = () => {
    const params = {
      task_id: taskId,
      user_id: userId,
    };
    // setLoader(true);
    getAllComments(params)
      .then((res) => {
        if (res.data && res.data.data) {
          setCommentList(res.data.data);
        } else {
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setCommentList([]);
        } else {
        }
      })
      .finally(() => {
        // setLoader(false);
      });
  };
  const handlePostComment = (comment) => {
    // setLoader(true);
    const data = {
      user_id: userId,
      task_id: taskId,
      commentText: comment,
      task_title: tasksDetails.task_title,
      task_assign_to: tasksDetails.task_assigned_to,
      task_owner: tasksDetails.owner_name.trim(),
    };
    postAComment(data)
      .then((res) => {
        fetchComments();
        toast.success("Comment added successfully!");
        // getAllTask(); // remove unneccesary api calling 
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { code, message } = error.response.data;
            toast.error(message);
            setTimeout(() => {
              navigate("/dashboard");
            }, 3000);
        } else {
          toast.error("An unexpected error occurred.");
        }
      })
      .finally(() => {
        // setLoader(false);
      });
  };

  const validationSchemaTimeLog = Yup.object({
    taskDescription: Yup.string()
      .required("Task description is required")
      .min(5, "Task description must contain at least 5 characters")
      .max(200, "Task description exceed 200 characters")
      .matches(/^[^\s][a-zA-Z0-9\s\-.,!"'?/()_$%&:;<>{}]*$/, {
        message: "Only alphanumeric are allowed",
      }),
    timeSpent: Yup.number()
      .required("Time spent is required")
      .min(0.01, "Invalid time format. Please use decimal format")
      .max(8, "Time spent cannot exceed 8 hours")
      .test(
        "is-decimal",
        "Invalid time format. Please use decimal format",
        (value) => /^\d+(\.\d{1,2})?$/.test(value)
      )
      .test(
        "max-decimal",
        "Time spent cannot exceed 8.00 hours",
        (value) => value <= 8.0
      ),
  });

  const formikTimeLog = useFormik({
    initialValues: { taskDescription: "", timeSpent: "" },
    validationSchema: validationSchemaTimeLog,
    onSubmit: async (values) => {
      const trimmedTaskDescription = values.taskDescription.trim();
      setIsSaving(true);
      toast.dismiss();
      try {
        const data = {
          task_id: taskId,
          time_logged: parseFloat(values.timeSpent).toFixed(2),
          activity: trimmedTaskDescription,
          user_id: userId,
        };
        await postTimeLogData(data);
        getActivityLog(taskId);
        setIsSaving(false);
        formikTimeLog.resetForm();
        toast.success("Time added successfully!");
      } catch (error) {
        if (error.response && error.response.data) {
          const { code, message } = error.response.data;
            toast.error(message);
            setTimeout(() => {
              navigate("/dashboard");
            }, 3000);
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    },
  });

  const validationSchemaComment = Yup.object({
    comment: Yup.string()
      .required("Comment is required")
      .min(5, "Comment must be at least 5 characters"),
  });

  const formikComment = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: validationSchemaComment,
    onSubmit: (values, { resetForm }) => {
      const trimmedComment = values.comment.trim();
      setIsSaving(true);
      toast.dismiss();
      handlePostComment(trimmedComment);
      setIsSaving(false);
      resetForm();
    },
  });

  const fetchBandsByStreamId = async (streamId) => {
    try {
      const response = await axios.get(
        `${API_URL}getBandsByStream?stream_id=${streamId}`
      );
      // console.log("bands list:", response.data.data);
      return response.data.data;
    } catch (error) {
      console.error("There was a problem fetching the bands:", error);
      return [];
    }
  };
  useEffect(() => {
    if (tasksDetails && tasksDetails.task_current_status) {
      localStorage.setItem(
        "task_current_status",
        tasksDetails.task_current_status
      );
      setSelectedStatus({
        value: tasksDetails.task_current_status,
        label: tasksDetails.task_current_status,
      });
    }
  }, [tasksDetails]);

  const handleStatusChange = (selectedOption) => {
    console.log("selectedOption : ", selectedOption);
    setSelectedStatus(selectedOption);
    localStorage.setItem("task_current_status", selectedOption.value);
    changeCardStatus(selectedOption);
  };

  const changeCardStatus = async (selectedOption) => {
    console.log("tasksDetails : =====", { tasksDetails, selectedStatus });
    const movedTaskData = {
      task_id: tasksDetails.task_id,
      task_current_status: selectedOption.label,
      task_from: tasksDetails.owner_name,
      task_assigned_to: tasksDetails.task_owner_id,
      task_changed_by: tasksDetails.owner_name,
      task_when_changed: new Date().toISOString(),
      band_id: selectedOption.value,
    };
    console.log("movedTaskData : ", movedTaskData);

    try {
      const response = await updateTaskStatusCycle(movedTaskData);
      if (response.data && response.data.code === 201) {
        toast.success("Status updated successfully");
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { code, message } = error.response.data;
          toast.error(message);
          setTimeout(() => {
            navigate("/dashboard");
          }, 3000);
      }
    }
  };

  useEffect(() => {
    const fetchBands = async () => {
      const streamId = localStorage.getItem("selectedStreamId");
      if (streamId) {
        const bands = await fetchBandsByStreamId(streamId);
        setBands(bands);
      }
    };
    fetchBands();
  }, []);

  const allowedUserIds = tasksDetails.allow_members_to_comment;


  const showCard = useSelector(state => state.showCard);

  useEffect(() => {
    if (showCard) {
      fetchTaskDetails(taskId);
    }
  }, [showCard]);

  const handleToggle = () => {
    handleSaveButtonClick();
    setActiveTab("details")
    dispatch(toggleShowCard());
  };

console.log("show card",showCard);


const inputRef = useRef(null);

const handleKeyDown = (e) => {
  if (e.key === "Enter") {
    e.preventDefault();
    addTag();
  }
};

const handleTagsChange = (e) => {
  if (e.target.value.trim() === "") return;
  addTag();
};

const addTag = () => {
  const value = inputRef.current.value.trim();
  if (value) {
    setEditedTags((prevTags) => [...prevTags, value]);
    inputRef.current.value = "";
  }
  console.log("editedTgs of addTg", editedTags);
};

useEffect(() => {
  console.log("Updated editedTags state:", editedTags);
  handleSaveButtonClick();
}, [editedTags]); 

const removeTag = (index) => {
  setEditedTags(editedTags.filter((_, i) => i !== index));
};

const fetchMembersByStreamId = (streamId) => { 
  console.log("fetch member api called:", streamId)
  if (streamId === null || streamId === "" || streamId === "null") {
    setMembers([]);
    return;
  }
  localStorage.setItem('CreateStream', streamId);

  if (streamId == MY_TASKS) {
    setMembers([{ member_id: userData?.user_id, member_name: userData?.user_name }]);
  } else {
    axios
      .get(`${API_URL}getMembersByStreamId`, {params:{
        stream_id: streamId,
  }})
      .then((response) => {
        if (response.data && response.data.code === 200) {
          localStorage.setItem('createStreamName', response.data.data.stream_name);
          setMembers(response.data.data.members);
        } else {
          console.error("Unexpected response data:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching members:", error);
      });
  }
  
};

const handleDelete = async (e, taskId) => {
  e.preventDefault();
  setConfirmationModalOpen(true);
}

const confirmDelete = async (e, taskId) => {
  // console.log("confirmDelete");
  // setLoader1(true);
  try {
    let res = await deleteTask(taskId, false);
    handleToggle();
    getAllTask();
    e.stopPropagation();
  } catch (error) {
    console.error("Error deleting task", error);
  } finally {
    // setLoader1(false);
  }
  setConfirmationModalOpen(false);
};

const cancelDelete = () => {
  setConfirmationModalOpen(false);
};

const priority = editedPriority || tasksDetails.task_priority;

const handleMemberChange = (e) => {
  const selectedMemberId = e.target.value;
  handleAssignedToChange(selectedMemberId); 
  
  const selectedMember = members.find((member) => member.member_id === parseInt(selectedMemberId));
  
  if (selectedMember) {
    setSelectedMemberImage(selectedMember.user_image);
  } else {
    setSelectedMemberImage('');
  }
};


  return (
    <Modal
    show={showCard} 
    onHide={handleToggle}
      className="task-detail-modal"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <div className="heading-left-col">
          <div className="TaskstatusText">{taskStatus}</div>
        </div>      
      </Modal.Header>
      <Modal.Body className="p-0 taskbodypart">
        <div className="">
      
            <div className=" create_Tasks taskDetailModalBox">
              
             <div className="taskdetailLeft">
             <div className="d-flex align-items-center justify-content-between w-100">
                <div className="assignedCustom priorityCustom">
                    <div className="">
                    {userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to ? (
                    <span className="custom_selectField">
                     <select
                     className={`tag_priority customInput ${
                        priority === "H"
                        ? ""
                        : priority === "L"
                        ? "bg-grey"
                        : priority === "M"
                        ? "bg-medium"
                        : ""
                    }`}
                     id="task_priority"
                     name="task_priority"
                     value={editedPriority}
                     onChange={handlePriorityChange}
                     onBlur={handleSaveButtonClick}
                   >
                   <option
                      value="H"
                      className="tag_priority" 
                    >
                      High
                    </option>
                    <option
                      value="M"
                      className="tag_priority bg-medium" 
                    >
                      Medium
                    </option>
                    <option
                      value="L"
                      className="tag_priority bg-grey" 
                    >
                      Low
                    </option>
                   </select>
                   </span>
                   
                    ) : (
                      <input
                        type="text"
                        className={`tag_priority ${
                          editedPriority === "H"
                            ? ""
                            : editedPriority === "L"
                            ? "bg-grey"
                            : "bg-medium"
                        }`}
                        id="task_priority"
                        name="task_priority"
                        placeholder="Priority"
                        value={getPriorityLabel(tasksDetails.task_priority)}
                        disabled
                      />
                    )
                    }
                  </div>                                     
                </div>
                  
                
             

              </div>

           


            <div className="clearfix"></div>
               {/* Title */}
               <div className="d-block w-100 mb-3 mt-2 ">
                  {userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to ? (
                    <>
                      <textarea
                       className="taskdetails-title"
                        style={{ ...style, width: '100%' }} // Set static width here
                        ref={textareaRef}
                        id="task_title"
                        name="task_title"
                        placeholder="Title"
                        value={editedTitle}
                        onChange={(e) => {
                          const lines = e.target.value.split('\n');
                          if (e.target.value.length <= 100 && lines.length <= 1) { 
                            handleTitleChange(e);
                            e.target.style.height = 'auto'; 
                            e.target.style.height = `${e.target.scrollHeight}px`; 
                          }
                        }}
                        onBlur={handleSaveButtonClick}
                        rows={1}
                      />
                    </>
                  ) : (
                    <>
                      <textarea
                       className="taskdetails-title"
                        style={{ ...style, width: '100%' }} 
                        ref={textareaRef}
                        id="task_title"
                        name="task_title"
                        placeholder="Title"
                        value={tasksDetails.task_title}
                        disabled
                        rows={1}
                      />
                    </>
                  )}
                </div>


          <div className="createdbyInfo">
          <img src={tasksDetails.task_owner_image} alt="" />
          <div>
            <span className="createdbythis">Created by {" "} {tasksDetails.owner_name}</span>
            <div>  on {tasksDetails.task_created_at ? formatDate(tasksDetails.task_created_at) : "- "}</div>
          </div>
          </div>


              <div className="boxesfullsBox">

                    {/* Assigned To */}
                 
                    <div className="boxesfulls">
                      <label>Assigned To</label>
                      <div className="assignedCustom" >
                      <div className="d-flex align-items-center">
                      <img 
                       src={selectedMemberImage || tasksDetails.task_assign_image || profile}
                          className="userimageLeft me-1"
                          alt=""
                  
                        />
                        
                          {userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to ? (
                            <span className="custom_selectField flex-grow-1" >
                              <select
                                className="customInput"
                                id="task_assigned_to"
                                name="task_assigned_to"
                                value={members.some(member => member.member_id === Number(editedAssignedTo)) ? editedAssignedTo : ""}
                                onChange={handleMemberChange}
                                onBlur={handleSaveButtonClick}
                                style={{ minWidth: '150px' }}
                              >
                                 <option value="" disabled>
                                  Select a member
                                </option>
                                {members &&
                                  members.map((member) => (
                                    <option key={member.member_id} value={member.member_id}>
                                      {member.member_name}
                                    </option>
                                  ))}
                              </select>
                            </span>
                          ) : (
                            <input
                              className="customInput"
                              type="text"
                              id="task_assigned_to"
                              name="task_assigned_to"
                              placeholder="Assigned To"
                              value={members.some(member => member.member_id === Number(tasksDetails.task_assigned_to)) ? tasksDetails.task_assign_name : "Unassigned"}
                              disabled
                             
                            />
                          )}
                        
                        </div>
                       
                      </div>
                    </div>

                  <div className="boxesfulls">
                      <label>Cost (£)</label>
                      {userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to ? (
                        <span className="costvalue">
                        <input
                          type="text"
                          className="customInput"
                          id="task_cost"
                          name="task_cost"
                          placeholder="Cost"
                          value={editedCost}
                          onChange={handleCostInputChange}
                          onBlur={handleSaveButtonClick}
                        />
                        </span>
                      ) : (
                        <span className="costvalue">
                        <input
                          type="number"
                          className="customInput"
                          id="task_cost"
                          name="task_cost"
                          placeholder="Cost"
                          value={tasksDetails.task_cost || "0"}
                          disabled
                        />
                        </span>
                      )}
                 </div>

                 <div className="boxesfulls taskTimehrs">
                    <label>Time (In hours)</label>
                    {userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to ? (
                      <input
                        type="text"
                        className="customInput"
                        id="task_time"
                        name="task_time"
                        placeholder="Time"
                        value={editedTime}
                        onChange={handleTimeInputChange}
                        onBlur={handleSaveButtonClick}
                      />
                    ) : (
                      <input
                        type="text"
                        className="customInput"
                        id="task_time"
                        name="task_time"
                        placeholder="Time"
                        disabled
                        value={tasksDetails.task_time || "0"}
                      />
                    )}
                  </div>

                  <div className="boxesfulls duedatefield">
                      <label>Due on</label>
                      {userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to ? (
                        <input
                          type="date"
                          onKeyDown={handleKeyDownETA}
                          className="customInput"
                          id="task_eta"
                          name="task_eta"
                          placeholder="ETA"
                          onChange={(e) => handleEtaChange(e.target.value)}
                          onBlur={handleSaveButtonClick}
                          min={getMinDate()}
                          value={editedETA}
                        />
                      ) : (
                        <input
                          type="text"
                          className="customInput"
                          id="task_eta"
                          name="task_eta"
                          placeholder="ETA"
                          disabled
                          value={fetchReadOnlyETA()}
                        />
                      )}
                      </div>

                  <div className="boxesfulls ">
                    
                    <label>Last Updated</label>

                    {tasksDetails.differenceInDays === 0 && (
                      <span className="lastupdatedtext" style={{ color: '' }}>Today</span>
                    )}
                    {tasksDetails.differenceInDays === 1 && (
                      <span className="lastupdatedtext" style={{ color: '' }}>Yesterday</span>
                    )}
                    {tasksDetails.differenceInDays > 1 && (
                      <span className="lastupdatedtext" style={{ color: tasksDetails.differenceInDays >= 5 ? ' ' : '' }}>{tasksDetails.differenceInDays} days ago</span>
                    )}

                   </div>
                  
              
                
                  <div className="boxesfulls tagsblock align-items-start">
                  <label className="pt-1">Tags</label>
                  <div className="d-block flex-grow-1">
                    <div className="tasktags">
                      <ul>
                        { editedTags.length === 0 && !(userData.user_id === tasksDetails.task_owner_id ||
                      userData.user_id === tasksDetails.task_assigned_to) ? (
                            <li>
                            <span className="text">No Tags</span>
                            </li>
                        )  : 
                        editedTags.map((tag, index) => (
                          <li key={index} className="tag">
                            <span className="text">{tag}</span>
                            {userData.user_id === tasksDetails.task_owner_id ||
                            userData.user_id === tasksDetails.task_assigned_to ? (
                              <span className="close" onClick={() => removeTag(index)}>
                                <img src={close} alt="close" />
                              </span>
                            ) : ""}
                          </li>
                        ))
                        }
                      </ul>
                      {userData.user_id === tasksDetails.task_owner_id ||
                      userData.user_id === tasksDetails.task_assigned_to ? (
                        <> 
                        <div className="tags-input-container">
                          <input
                            ref={inputRef}
                            type="text"
                            onKeyDown={handleKeyDown}
                            onBlur={(e) => {
                              handleTagsChange(e);
                              setIsInputFocused(false); 
                            }}
                            onFocus={() => setIsInputFocused(true)}
                            className="tags-input customInput"
                            id="task_tags"
                            name="task_tags"
                            placeholder="Add a tag"
                          />
                        </div>
                        {isInputFocused && (
                          <div className="tags mt-1">* Press enter to add the tags</div>
                        )}
                        </>
                      ) : ""}
                    </div>
                    
                  </div>
                </div>

                { (userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to) && 
                <div className="d-flex w-100 justify-content-end">
                 <span className="deleteTaskbutton" onClick={handleDelete}>Delete</span> 
                 </div>
                }


              </div>
              </div>

           <div className="custom-tab modal-tabs">
           
                <div className="tab-buttons">
                  <button
                    className={activeTab === "details" ? "active" : "inactive"}
                    onClick={() => handleTabClick("details")}
                  >
                    Details
                  </button>
                  <button
                    className={activeTab === "comment" ? "active" : "inactive"}
                    onClick={() => handleTabClick("comment")}
                  >
                    Comments ({commentList.length})
                  </button>
                  <button
                    className={activeTab === "time" ? "active" : "inactive"}
                    onClick={() => handleTabClick("time")}
                  >
                    Timesheet
                  </button>
                </div>
                <div className="tab-content">

                  {activeTab === "details" && (
                  <>
                    <div className="taskdetrailBlock">
                      {tasksDetails.task_owner_id === userData.user_id || tasksDetails.task_assigned_to === userData.user_id ? (
                        <div className="taskdetrailBlock_editor">
                          <ReactQuill
                            value={editedDetails}
                            onChange={handleDetailsChange}
                            onBlur={handleSaveButtonClick}
                            placeholder="Enter task details..."
                            theme="snow"
                            modules={{
                              toolbar: [
                                [{ size: [] }],
                                ['bold', 'italic', 'underline'],
                                ['link'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                              ],
                            }}
                          />
                          <div className="d-flex justify-content-between w-100">
                          {/* <button onClick={handleSaveButtonClick} className="btn btn-primary btn-sm ms-auto mt-2">
                            Save
                          </button> */}
                          </div>
                        </div>
                      ) : (
                        <div className="taskdetailViewModes">
                         <div dangerouslySetInnerHTML={{ __html: tasksDetails.task_details }} />
                        </div>
                      )}
                    </div>
                  </>
                )}


                  {activeTab === "comment" && (
                    <div className="commentflex">
                    
                        <section className="detail_comments"></section>
                        {(tasksDetails.task_owner_id === userData.user_id ||
                          tasksDetails.task_assigned_to ===
                            userData.user_id) && (
                          <form onSubmit={formikComment.handleSubmit}>
                            <div className="task-comment_box">
                              <div className="me-3 w-100">
                                <textarea
                                  className={`form-control ${
                                    formikComment.touched.comment &&
                                    formikComment.errors.comment
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="comment"
                                  id="comment"
                                  cols="30"
                                  rows="10"
                                  placeholder="Add your comment here"
                                  value={formikComment.values.comment}
                                  onChange={formikComment.handleChange}
                                  onBlur={formikComment.handleBlur}
                                ></textarea>
                                {formikComment.touched.comment &&
                                  formikComment.errors.comment && (
                                    <div className="invalid-feedback">
                                      {formikComment.errors.comment}
                                    </div>
                                  )}
                              </div>
                              <div className="w-100 text-end">
                                <button
                                  className="btn btn-sm btn-primary align-self-end"
                                  type="submit"
                                  disabled={isSaving}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </form>
                        )}
                        <CommentList comments={commentList} />
                     
                    </div>
                  )}
                  {activeTab === "time" && (
                   
                      <form onSubmit={formikTimeLog.handleSubmit} className="formmaxheight">
                        <div className="timesheetOuter">
                       
                          {(tasksDetails.task_owner_id === userData.user_id ||
                            tasksDetails.task_assigned_to ===
                              userData.user_id) && (
                              <div className="timesheetForm mb-3">
                              <div className="row">
                              <div className="col-lg-12 mb-2 ">
                                <label className="form-label">
                                  What you did for this task?
                                </label>
                                <textarea
                                  className={`form-control ${
                                    formikTimeLog.touched.taskDescription &&
                                    formikTimeLog.errors.taskDescription
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="taskDescription"
                                  value={formikTimeLog.values.taskDescription}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 200) {
                                      formikTimeLog.handleChange(e);
                                      formikTimeLog.setFieldTouched(
                                        "taskDescription",
                                        true,
                                        false
                                      );
                                    }
                                  }}
                                  onBlur={formikTimeLog.handleBlur}
                                  cols="30"
                                  rows="10"
                                  placeholder="Enter task here"
                                ></textarea>
                                {formikTimeLog.touched.taskDescription &&
                                  formikTimeLog.errors.taskDescription && (
                                    <div className="invalid-feedback">
                                      {formikTimeLog.errors.taskDescription}{" "}
                                    </div>
                                  )}{" "}
                              </div>
                              <div className="col-lg-12">
                                <label className="form-label">
                                  How much time spent ?
                                </label>
                                <div className="d-flex align-items-start addtimefield">
                                  <div className="flex-grow-1">
                                    {" "}
                                    <input
                                      type="text"
                                      className={`form-control w-100 ${
                                        formikTimeLog.touched.timeSpent &&
                                        formikTimeLog.errors.timeSpent
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      id="timeSpent"
                                      name="timeSpent"
                                      value={formikTimeLog.values.timeSpent}
                                      onChange={handleTimeSpentChange}
                                      onBlur={formikTimeLog.handleBlur}
                                      placeholder="Use '0.5' for half an hour"
                                    />
                                    {formikTimeLog.touched.timeSpent &&
                                      formikTimeLog.errors.timeSpent && (
                                        <div className="invalid-feedback">
                                          {formikTimeLog.errors.timeSpent}
                                        </div>
                                      )}
                                  </div>
                                  <button
                                    className="btn btn-primary btn-sm ms-2 savebtntime"
                                    type="submit"
                                    disabled={
                                      !formikTimeLog.isValid || isSaving
                                    }
                                  >
                                    Save{" "}
                                  </button>
                                </div>
                              </div>{" "}
                            </div>
                            </div>
                          )}
                          
                      {activityList.timeLogs ? (

                        <div className="timesheetInfos">
                          <div className="timesheetInfos-header ">
                            <h4 className="mb-0 mt-0">Timesheet Details</h4>
                            <h5 className="mb-0 mt-0">Total {activityList.totalTime} Hrs</h5>
                          </div>
                          <div className="timesheetInfos-content">
                            {activityList.timeLogs.map((activity, index) => (
                              <div className="timesheetRow" key={index}>
                                <img 
                                  // src={activity.user_info.profile_picture || "default-image-url"} 
                                  src={userData?.image}
                                  className="timesheetAuthorImg" 
                                  alt={activity.user_info.user_name}
                                />
                                <div className="timesheetRight">
                                  <div className="authorInfoTop">
                                    <strong>{activity.user_info.user_name}</strong> 
                                    <span>{formatDate(activity.createdAt)}</span> 
                                    <h5 className="ms-auto me-0">{activity.time_logged}</h5>
                                  </div>
                                  <p>{activity.activity}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className="text-center timesheetnoFound">No Timesheet Found</div>
                      )}
                      </div>
                      </form>
                    
                  )}
                </div>
              
              </div>


            </div>
         
          {loader && <Loader />}
          <ToastContainer />
         
          {isConfirmationModalOpen && (
        <div className="confirmation-modal modal_class" style={{ width: "50%", overflow: "auto", zIndex: 1000 }}>
          <div className="modal-content">
            <div className="modal-body text-center">
              <div className="alerticon"></div>
              {/* <h6 class="mt-3 text-center d-block w-100">Delete Task</h6> */}
              <p className="d-block w-100 mt-3 text-center alertmodaltext">Are you sure you want to delete this task?</p>
              <div className="button-container popupbtns mt-4">
              <button onClick={cancelDelete} className="btn btn-secondary btn-sm me-3">No, keep it</button>
                <button onClick={(e) => { confirmDelete(e, tasksDetails.task_id) }} className="btn btn-primary btn-sm deletebtns">Yes, delete it</button>
                
              </div>
            </div>

          </div>
        </div>
      )}
        </div>
      </Modal.Body>
     
    </Modal>

    
  );
};
export default TaskDetailsPage;
