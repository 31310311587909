import { createSlice } from '@reduxjs/toolkit';

const showCardEdit = createSlice({
  name: 'showCardEdit',
  initialState: false,
  reducers: {
    toggleShowCardEdit: state => !state
  }
});

export const { toggleShowCardEdit } = showCardEdit.actions;
export default showCardEdit.reducer;
