import { createSlice } from '@reduxjs/toolkit';

const showCardSlice = createSlice({
  name: 'showCard',
  initialState: false,
  reducers: {
    toggleShowCard: state => !state,
    setShowCardFalse: () => false,
  }
});

export const { toggleShowCard , setShowCardFalse  } = showCardSlice.actions;
export default showCardSlice.reducer;
