import React from "react";
import { Bar } from "react-chartjs-2";

function TeamPriorityChart({ data }) {
  if (!data || !data.to_do || !Array.isArray(data.to_do)) {
    console.error("Invalid data format. Expected 'to_do' to be an array.");
    // Return the chart with default values (all counts set to 0)
    return <ChartWithData chartData={{ labels: [], datasets: [] }} />;
  }
  const toDoData = data.to_do[0] || {};
  const inProgressData = data.in_progress && data.in_progress[0] ? data.in_progress[0] : {};
  const chartData = {
    labels: ['H', 'M', 'L'],
    datasets: [
      {
        label: 'To Do',
        data: [parseInt(toDoData.H) || 0, parseInt(toDoData.M) || 0, parseInt(toDoData.L) || 0],
        backgroundColor: ['rgba(255,99,132,1)'],
        borderColor: ['rgba(255,99,132,1)'],
        borderWidth: 1,
        barThickness: 50,
      },
      {
        label: 'In Progress',
        data: [parseInt(inProgressData.H) || 0, parseInt(inProgressData.M) || 0, parseInt(inProgressData.L) || 0],
        backgroundColor: ['rgba(75,192,192,1)'],
        borderColor: ['rgba(75,192,192,1)'],
        borderWidth: 1,
        barThickness: 50,
      },
    ],
  };
  return <ChartWithData chartData={chartData} />;
}
function ChartWithData({ chartData }) {
  const allValues = chartData.datasets.flatMap(dataset => {
    return dataset.data.map(value => parseInt(value, 10) || 0);
  });
  const highestValue = Math.max(...allValues);
  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: true,
        max: Math.round((highestValue + 5000) / 2) * 5,
        stepSize: 10,
        barPercentage: 1,
      },
    },
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'end',
        align: 'top',
        formatter: (value, context) => {
          if (context.datasetIndex === context.chart.data.datasets.length - 1) {
            let sum = 0;
            context.chart.data.datasets.forEach((dataset) => {
              sum += dataset.data[context.dataIndex];
            });
            return sum.toLocaleString();
          } else {
            return '';
          }
        },
        display: true,
      },
    },
  };
  const emptyOptions = {
    scales: {
      x: {
        stacked: true,
        type: "category",
      },
      y: {
        beginAtZero: true,
        stacked: true,
        stepSize: 10,
        barPercentage: 1,
      },
    },
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: 'Stacked',
    },
  };

  if (!chartData || chartData.datasets.length === 0) {
    return (
      <div>
        <Bar options={emptyOptions} data={{ labels: [], datasets: [] }} />
      </div>
    );
  }
  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  );
}

export default TeamPriorityChart;
