import { configureStore } from '@reduxjs/toolkit';
import showCardReducer from './action/showCardSlice';
import cardIdReducer from './action/showCardSlice';
import showCardEditReducer from './action/showCardSlice';
import showCreateReducer from './action/showCreateCardSlice';

const store = configureStore({
  reducer: {
    showCard: showCardReducer,
    cardId: cardIdReducer,
    showCardEdit: showCardEditReducer,
    showCreateCard: showCreateReducer
  }
});

export default store;
