import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import logo from "../../assets/images/login-logo-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../lib/network/api";
import Edit from "../../assets/images/edit.svg";
import Profile from "../../assets/images/profile.svg";
import Loader from "../../components/Loader/Loader";

const validationSchema = Yup.object({
  user_email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .required("Required"),
  user_name: Yup.string().required("Required")
    .matches(/^[a-zA-Z]+(\s+[a-zA-Z]+)*\s*$/, "Invalid username format"),
  user_reporting_to: Yup.string().required("Required")
    .matches(/^[a-zA-Z]+(\s+[a-zA-Z]+)*$/, "Required"),
  image: Yup.mixed().required("Image is required"),
});
const Register = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      user_email: "",
      password: "",
      user_name: "",
      user_reporting_to: "",
      image: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        let daat = {
          user_name: values.user_name,
          user_email: values.user_email,
          user_reporting_to: values.user_reporting_to,
          password: values.password,
          image: selectedFile,
        };
        const response = await axios.post(`${API_URL}user/register`, daat);
        if (response.status) {
          localStorage.setItem(
            "loggedinUser",
            JSON.stringify(response?.data?.data?.user)
          );
          localStorage.setItem(
            "token",
            JSON.stringify(response?.data?.data?.token)
          );
          navigate("/my-tasks");
          setTimeout(() => {
            setLoader(false);
          }, 2000);
        }
      } catch (error) {
        console.error("API Error:", error.message);
        setLoginError("Invalid email or password. Please try again.");
        setLoader(false);
      }
    },
  });
  useEffect(() => {
    setLoginError(null);
  }, [formik.values.email, formik.values.password]);
  const handleFileChange = async (e) => {
    formik.setFieldValue("image", e.target.files[0]);
    const file = e.target.files[0];
    try {
      const apiUrl = `${API_URL}user/s3image`;
      const fileName = file.name;
      const fileType = file.type;
      axios
        .get(apiUrl, {
          params: {
            fileName: fileName,
            fileType: fileType,
          },
        })
        .then((response) => {
          let url = response?.data?.data?.signedUrlObj?.signedRequest;
          let image = response?.data?.data?.signedUrlObj?.url;
          setSelectedFile(image);
          let data = file;
          let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: url,
            headers: {
              "Content-Type": file,
            },
            data: data,
          };
          axios
            .request(config)
            .then((response) => {
            })
            .catch((error) => {
            });
        })
        .catch((error) => {
        });
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="login-cont">
          <div className="login-left-col">
            <img src={logo} className="img-fluid" alt="Login Logo" />
          </div>
          <div className="login-right-col">
            <div className="login-content-holder">
              <h4 className="mb-3">Register</h4>
              {loginError && (
                <div className="alert alert-danger" role="alert">
                  {loginError}
                </div>
              )}
              <div className="mb-3 d-flex justify-content-center">
                <div className="profile-upload">
                  <input id="file" type="file" onChange={handleFileChange} />
                  <label className="label" htmlFor="file">
                    <figure style={{ position: "relative" }}>
                      {formik.values.image ? (
                        <img
                          src={URL.createObjectURL(formik.values.image)}
                          id="output"
                          alt="Profile"
                        />
                      ) : (
                        <img src={Profile} id="output" alt="Alt" />
                      )}
                    </figure>
                    <i className="edit_Icon_Profile">
                      <img src={Edit} alt="Edit" />
                    </i>
                  </label>
                  {formik.touched.image && formik.errors.image && (
                    <div className="error-message text-danger">
                      {formik.errors.image}
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  className="form-control"
                  placeholder="name@example.com"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.user_email}
                />
                {formik.touched.user_email && formik.errors.user_email ? (
                  <div className="error-message text-danger">
                    {formik.errors.user_email}
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  User Name
                </label>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"
                  className="form-control"
                  placeholder="Enter your user name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.user_name}
                />
                {formik.touched.user_name && formik.errors.user_name ? (
                  <div className="error-message text-danger">
                    {formik.errors.user_name}
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Report to
                </label>
                <input
                  type="text"
                  id="user_reporting_to"
                  name="user_reporting_to"
                  className="form-control"
                  placeholder="Enter your Reporter name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.user_reporting_to}
                />
                {formik.touched.user_reporting_to &&
                  formik.errors.user_reporting_to ? (
                  <div className="error-message text-danger">
                    {formik.errors.user_reporting_to}
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder="**********"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.values.password && (
                    <button
                      type="button"
                      className="eye-button"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        border: "none",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                      />
                    </button>
                  )}
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className="error-message text-danger">
                    {formik.errors.password}
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <p>
                  Do you have an account?
                  <Link to={"/"} style={{ marginLeft: "5px" }}>
                    Login
                  </Link>
                </p>
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </form>
      {loader && <Loader />}
    </div>
  );
};
export default Register;
