import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import logoutImg from "../../assets/images/logoutimg.svg";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeNavItem, setActiveNavItem] = useState('');
  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);
  const handleNavigation = (path, itemName) => {
    navigate(path);
    setActiveNavItem(itemName);
  };

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.startsWith('/dashboard')) {
      setActiveNavItem('Dashboard');
    } else if (pathname.startsWith('/task')) {
      if (pathname.startsWith('/task-details')) {
        setActiveNavItem('Home');
      } else {
        setActiveNavItem('Activity');
      }
    } else if (pathname.startsWith('/my-tasks')) {
      setActiveNavItem('Boards');
    } else if (pathname.startsWith('/tags')) {
      setActiveNavItem('Tags Cloud');
    }
    else if (pathname.startsWith('/streams')) {
      setActiveNavItem('Streams');
    }
  }, [location.pathname]);

  return (
    <header>
      <Navbar expand="lg">
        <div className="container-fluid">
          <Navbar.Brand href="/dashboard">
            <img src={logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="navbar-nav m-auto mt-0 mb-lg-0">
              <Nav.Link
                href=""
                onClick={() => handleNavigation("/dashboard", "Dashboard")}
                className={activeNavItem === "Dashboard" ? "active" : ""}
              >
                Dashboard
              </Nav.Link>
              <Nav.Link
                href=""
                onClick={() => handleNavigation("/my-tasks", "Boards")}
                className={activeNavItem === "Boards" ? "active" : ""}
              >
                Boards
              </Nav.Link>

              {/* <Nav.Link
                href=""
                onClick={() => handleNavigation("/task", "Activity")}
                className={activeNavItem === "Activity" ? "active" : ""}
              >
                Create Task
              </Nav.Link> */}
              <NavDropdown
                title="Reports"
                id="collasible-nav-dropdown"
                className={activeNavItem === "Team Dashboard" || activeNavItem === "User Dashboard" ? "active" : ""}
              >
                <NavDropdown.Item
                  onClick={() => handleNavigation("/reports", "Team Dashboard")}
                  className={activeNavItem === "Team Dashboard" ? "active" : ""}
                >
                  Team Dashboard
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavigation("/userdashboard", "User Dashboard")}
                  className={activeNavItem === "User Dashboard" ? "active" : ""}
                >
                  User Dashboard
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href=""
                onClick={() => handleNavigation("/tags", "Tags Cloud")}
                className={activeNavItem === "Tags Cloud" ? "active" : ""}
              >
                Tags Cloud
              </Nav.Link>
              {userData?.user_id === 46 && (
                <Nav.Link
                  href=""
                  onClick={() => handleNavigation("/streams", "Streams")}
                  className={activeNavItem === "Streams" ? "active" : ""}
                >
                  Streams
                </Nav.Link>
              )}
            </Nav>
            <div className="top-userlink">
              <img src={userData?.image} alt="" className="Img-round me-1" />
              {userData?.user_name}
            </div>

            <NavDropdown
              title={<img src={logoutImg} alt="" />}
              id="collasible-nav-dropdown"
              className="logoutbtn"
            >
              <NavDropdown.Item
                className="border-0"
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                  window.location.reload()
                }}
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
