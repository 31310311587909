import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const StreamLeadTimeReportGraph = ({ data }) => {

    const processedData = data.map((item) => ({
        ...item,
        average_days_to_completion: Number(item.average_days_to_completion),
      }));

      const chartHeight =  Math.max(processedData.length * 40) + 100;

  return (
    <ResponsiveContainer width="100%"   height={chartHeight}>
      <BarChart
        layout="vertical"
        width={600}
        height={chartHeight}
        data={processedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          domain={[0, (dataMax) => Math.ceil(dataMax / 2) * 2]}
          tickFormatter={(tick) => `${tick}D`}
        />
        <YAxis 
          type="category" 
          dataKey="stream_name" 
          interval={0} 
          width={150}  
          tick={{ padding: 10 }}  
          style={{ fontSize: '11px'}}
        />
        <Tooltip formatter={(value) => `${value} Days`} />
        <Bar dataKey="average_days_to_completion" fill="#8884d8" barSize={20}  name="Average Days to Completion" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StreamLeadTimeReportGraph;
