import React, { useState } from 'react';
import Layout from '../layout/Layout';

const Analytics = () => {
  const [analyticsData, setAnalyticsData] = useState([
    {
      stream: 'Client 360 Feedback',
      todo: [6],
      inProgress: [4, 5, 6],
      onHold: [7, 8, 9],
      doneByMember: [10, 11, 12],
      completed: [13, 14, 15]
    },
    {
      stream: 'Cross Selling PM',
      todo: [16, 17, 18],
      inProgress: [19, 20, 21],
      onHold: [22, 23, 24],
      doneByMember: [25, 26, 27],
      completed: [28, 29, 30]
    },
    {
      stream: 'Cross Selling - Tech Team',
      todo: [31, 32, 33],
      inProgress: [34, 35, 36],
      onHold: [37, 38, 39],
      doneByMember: [40, 41, 42],
      completed: [43, 44, 45]
    },
    {
      stream: 'Escalation and Challenges',
      todo: [46, 47, 48],
      inProgress: [49, 50, 51],
      onHold: [52, 53, 54],
      doneByMember: [55, 56, 57],
      completed: [58, 59, 60]
    },
    {
      stream: 'Goals & Agenda 2024',
      todo: [61, 62, 63],
      inProgress: [64, 65, 66],
      onHold: [67, 68, 69],
      doneByMember: [70, 71, 72],
      completed: [73, 74, 75]
    },
    {
      stream: 'Hold Project Revival - AU',
      todo: [76, 77, 78],
      inProgress: [79, 80, 81],
      onHold: [82, 83, 84],
      doneByMember: [85, 86, 87],
      completed: [88, 89, 90]
    },
    {
      stream: 'Hold Project Revival – UK',
      todo: [91, 92, 93],
      inProgress: [94, 95, 96],
      onHold: [97, 98, 99],
      doneByMember: [100, 101, 102],
      completed: [103, 104, 105]
    },
    {
      stream: 'PMO Project Pipeline',
      todo: [106, 107, 108],
      inProgress: [109, 110, 111],
      onHold: [112, 113, 114],
      doneByMember: [115, 116, 117],
      completed: [118, 119, 120]
    },
    {
      stream: 'Project Management Office',
      todo: [121, 122, 123],
      inProgress: [124, 125, 126],
      onHold: [127, 128, 129],
      doneByMember: [130, 131, 132],
      completed: [133, 134, 135]
    },
    {
      stream: 'Projects Revival - AU & UK',
      todo: [136, 137, 138],
      inProgress: [139, 140, 141],
      onHold: [142, 143, 144],
      doneByMember: [145, 146, 147],
      completed: [148, 149, 150]
    },
    {
      stream: 'Senior PMs and TLs',
      todo: [151, 152, 153],
      inProgress: [154, 155, 156],
      onHold: [157, 158, 159],
      doneByMember: [160, 161, 162],
      completed: [163, 164, 165]
    },
    {
      stream: 'Shopify - Invite for App Demo',
      todo: [166, 167, 168],
      inProgress: [169, 170, 171],
      onHold: [172, 173, 174],
      doneByMember: [175, 176, 177],
      completed: [178, 179, 180]
    },
    {
      stream: 'Tech Vision',
      todo: [166, 167, 168],
      inProgress: [169, 170, 171],
      onHold: [172, 173, 174],
      doneByMember: [175, 176, 177],
      completed: [178, 179, 180]
    },
    {
      stream: 'Test Stream',
      todo: [166, 167, 168],
      inProgress: [169, 170, 171],
      onHold: [172, 173, 174],
      doneByMember: [175, 176, 177],
      completed: [178, 179, 180]
    },
  ]);

  const columns = ['#', 'Stream', 'To Do', 'In Progress', 'On Hold', 'Done by Member', 'Completed'];

  return (
    <Layout>
      <div className="container-fluid">
        <div class="header-tp-action"><h4 class="mb-2">Completion Analytics</h4></div>
        <table className='table'>
          <thead>
            <tr >
              {columns.map((column, index) => (
                <th key={index} >{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {analyticsData.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.stream}</td>
                <td>{row.todo.reduce((acc, val) => acc + val, 0)}</td>
                <td>{row.inProgress.reduce((acc, val) => acc + val, 0)}</td>
                <td>{row.onHold.reduce((acc, val) => acc + val, 0)}</td>
                <td>{row.doneByMember.reduce((acc, val) => acc + val, 0)}</td>
                <td>{row.completed.reduce((acc, val) => acc + val, 0)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Analytics;



