import React from 'react'
import resetIcon from "../../assets/images/login-logo-white.svg"
const SetNewPassword = () => {
  return (
    <div class="login-cont">
    <div class="login-left-col">
      <img src={resetIcon} class="img-fluid"/>
    </div>
    <div class="login-right-col">
        <div class="login-content-holder">
            <h4 class="mb-3">New Password</h4>
          
            <div class="mb-3">
              <label for="" class="form-label"> Enter New Password</label>
              <input type="text" class="form-control" id="" placeholder=""/>
            </div>
            <div class="mb-3">
              <label for="" class="form-label">Confirm Password</label>
              <input type="text" class="form-control" id="" placeholder=""/>
            </div>
            {/* <p class="text-end">
              <a href="#">Login</a>
            </p> */}
             <div className="mb-3">
                <button type="submit" className="btn btn-primary w-100 m-0">
                  Confirm
                </button>
              </div>

        </div>
    </div>
  </div>
  )
}
export default SetNewPassword