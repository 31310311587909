export const transformData = (inputData) => {
  // console.log("transformedData component: ", inputData);

  /**
   * @comment Static band with card for testing for stream id 19
   */
  // inputData.push({
  //   "task_id": 2306,
  //   "task_title": "Dynamic Bands testing title",
  //   "task_owner_id": 46,
  //   "task_owner": "Arvind Bhambhani",
  //   "task_eta": 0,
  //   "task_priority": "M",
  //   "task_details": "<p>Dynamic Bands testing description</p>",
  //   "task_tags": [
  //     "dynamic"
  //   ],
  //   "eta_in_date_time": "2024-07-19T18:30:00.000Z",
  //   "task_cost": "",
  //   "task_time": "",
  //   "band_id": 1,
  //   "allow_members_to_comment": [],
  //   "stream_name": "AU Projects Revival",
  //   "task_current_status": "D-TODO",
  //   "task_from": "Arvind Bhambhani",
  //   "task_when_changed": "2024-07-20T07:55:38.691Z",
  //   "task_changed_by": "Arvind Bhambhani",
  //   "task_assigned_to": "SHWETA CHAUHAN",
  //   "user_image": "https://cpms-media-bucket.s3.amazonaws.com/user-profile-images/1706966980754_Ellipse 4.png",
  //   "task_assigned_to_id": 101,
  //   "total_comments": "0",
  //   "task_update_days": 0,
  //   "latestActivity": "TaskStatusChange",
  //   "latestDate": "2024-07-20T07:55:38.691Z",
  //   "differenceInDays": 0
  // })

  const transformedData = [];
  const desiredTitles = JSON.parse(localStorage.getItem("Bands") ) || [];
  // console.log("desired title ", desiredTitles);
  /**
   * @comment Commented these static bands now we are getting dynamic bands
   */
  // const desiredTitles = [
  //   "To Do",
  //   "In Progress",
  //   "On Hold",
  //   "Done by member (In Review)",
  //   "Completed/ Approved",
  // ];

  // If inputData is empty, create default sections with empty arrays
  if (inputData.length === 0) {
    for (const title of desiredTitles) {
      transformedData.push({ title, items: [] });
      // console.log("title", title);
    }
    return transformedData;
  }
  // console.log("transformedData after pushed: ", transformedData)
  const sections = desiredTitles.reduce((obj, title) => {
    obj[title] = [];
    return obj;
  }, {});
  const groupedTasks = inputData.reduce((groups, task) => {
    // console.log("group and task", groups, task);
    // let status = task.task_current_status;
    let status = task.band_id;
    if (!groups[status]) {
      groups[status] = [];
    }
    groups[status].push(task);
    return groups;
  }, {});
  for (const [status, tasks] of Object.entries(groupedTasks)) {
    sections[status] = tasks.map((task) => ({
      title: task.task_title,
      task_status_cycle: status,
      count: task.total_comments,
      priority:
        task.task_priority === "H"
          ? "High"
          : task.task_priority === "M"
            ? "Medium"
            : "Low",
      description: task.task_details,
      task_id: task.task_id,
      user_image: task.user_image,
      task_assign_to: task.task_assigned_to,
      task_owner: task.task_owner,
      task_when_changed: task.task_when_changed,
      differenceInDays: task.differenceInDays,
      task_update_days: task.task_update_days,
      task_current_status:
        task.task_current_status,
      estimation_Date: task.estimation_Date,
      task_assigned_to_id: task.task_assigned_to_id,
      task_from: task.task_from,
      stream_name: task.stream_name,
      task_cost: task.task_cost,
      task_time: task.task_time,
      task_owner_id: task.task_owner_id,
      task_assigned_to_name: task.task_assigned_to_name,
      eta_in_date_time: task.eta_in_date_time,
      task_priority: task.task_priority,
      band_id: task.band_id
    }));
  }
  for (const title of desiredTitles) {
    transformedData.push({ title, items: sections[title] });
  }
  return transformedData;
};
