import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/Layout';
import {  getDashboardCardsCount, getDashboardStreamTimeReport, getDashbordStreamReport, getDashbordUpcomingTasks } from "../../lib/network/api";
import Loader from "../Loader/Loader";
import StreamReport from './StreamReportGraph';
import StreamLeadTimeReportGraph from './StreamLeadTimeReportGraph';
import allmembers from "../../assets/images/all-members-icon.png";
import member from "../../assets/images/member-icon.png";


const StreamDashboard = () => {
    const [loader, setLoader] = useState(false);
    const [tasksInfo, setTasksInfo] = useState();
    const [upcomingTasksInfo, setUpcomingTasksInfo] = useState([]);
    const [streamReportData, setStreamReportData] = useState([]);
    const [streamLeadTimeReportData, setStreamLeadTimeReportData] = useState([]);

    const loggedinUser = localStorage.getItem("loggedinUser");
    const userData = JSON.parse(loggedinUser);
    const userId = userData.user_id;
    useEffect(() => {
        localStorage.removeItem("selectedMember")
        localStorage.removeItem("selectedPriority")

    })
    useEffect(() => {
        fetchTaskInfo();
        fetchUpcomingTasks();
        fetchStreamReportGraphData();
        fetchStreamLeadTimeReportGraphData();
    }, [userId]);


    const fetchTaskInfo = () => {
        setLoader(true);
    
        getDashboardCardsCount(userId)
            .then((response) => {
                if (response.data) {
                    setTasksInfo(response.data.data);
                } else {
                    console.error('Unexpected response data format:', response.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const fetchUpcomingTasks = () => {
        setLoader(true);
       
        getDashbordUpcomingTasks(userId)
            .then(response => {
                if (response.data) {
                    setUpcomingTasksInfo(response.data.data);
                } else {
                    console.error('Unexpected response data format:', response.data);
                } setLoader(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoader(false);
            });
    }

    const fetchStreamReportGraphData = () => {
        setLoader(true);

        getDashbordStreamReport(userId)
            .then(response => {
                if (response.data) {
                    setStreamReportData(response.data.data);
                } else {
                    console.error('Unexpected response data format:', response.data);
                } setLoader(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoader(false);
            });
    }

    const fetchStreamLeadTimeReportGraphData = () => {
        setLoader(true);

        getDashboardStreamTimeReport(userId)
            .then(response => {
                if (response.data) {
                    setStreamLeadTimeReportData(response.data.data);
                } else {
                    console.error('Unexpected response data format:', response.data);
                } setLoader(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoader(false);
            });
    }

    const getPriorityLabel = (priority) => {
        switch (priority) {
            case 'H':
                return 'High';
            case 'M':
                return 'Medium';
            case 'L':
                return 'Low';
            default:
                return 'Unknown';
        }
    };
    
    const getPriorityClass = (priority) => {
        switch (priority) {
            case 'H':
                return 'bg-high';
            case 'M':
                return 'bg-medium';
            case 'L':
                return 'bg-grey';
            default:
                return 'bg-default';
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    };


    return (
        <Layout>
            <div className='bgwhiteFull dashboardfullPage'>
                <div className='pageHeader border-0 mb-0'>
                    <div className='container-fluid'>
                        <h1 className='pageTitleName mb-0'>Dashboard</h1>
                    </div>
                </div>

                <div className='pageBody'>
                    <div className='container-fluid'>
                    <h1 className='heading-line-through'><span> <img 
                       src={member}
                          className="me-1"
                          alt=""
                  
                        /> Assigned to me</span></h1>
                        <div className='d-flex flex-wrap w-100'>
                            <div className='dash-left-cards'>
                                <div className='box-block mb-3'>
                                    <div className='dash-cards first-card'>
                                        <h6 className='mb-0'>{tasksInfo?.incomplete_tasks_count ? tasksInfo?.incomplete_tasks_count : "0" }</h6>
                                        <span>Incomplete tasks</span>
                                    </div>
                                </div>
                                <div className='box-block mb-3'>
                                    <div className='dash-cards second-card'>
                                        <h6 className='mb-0'>{tasksInfo?.no_updates_count ? tasksInfo?.no_updates_count : "0"}</h6>
                                        <span>Tasks not updated for more than 2 weeks</span>
                                    </div>
                                </div>
                                <div className='box-block mb-3'>
                                    <div className='dash-cards third-card'>
                                        <h6 className='mb-0 text-danger'>{tasksInfo?.due_tasks_count  ? tasksInfo?.due_tasks_count : "0"}</h6>
                                        <span>Due Tasks </span>
                                    </div>
                                </div>
                            </div>
                            <div className='dash-topright-block'>
                                <div className='box-block mb-3'>
                                    <h4 className='block-title-name'>Upcoming Tasks</h4>
                                    <div className='table-responsive upcomingtasksBox'>
                                        <table className='table border-0'>
                                            <thead className='sticky-top'>
                                                <tr>
                                                    <th>Task Name</th>
                                                    <th>Stream Name</th>
                                                    <th>Band Name</th>
                                                    <th>Priority</th>
                                                    <th>Due Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {upcomingTasksInfo.length > 0 ? (
                                              upcomingTasksInfo.map(task => (
                                                <tr key={task.task_id}>
                                                    <td>{task.task_title}</td>
                                                    <td>{task.stream_name}</td>
                                                    <td>{task.band_name}</td>
                                                    <td>
                                                        <span className={`tag_priority ${getPriorityClass(task.task_priority)}`}>
                                                            {getPriorityLabel(task.task_priority)}
                                                        </span>
                                                    </td>
                                                    <td>{formatDate(task.due_date)}</td>
                                                </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5">No upcoming tasks found.</td>
                                                    </tr>
                                                )}
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                     
                        <h1 className='heading-line-through'><span> <img 
                       src={allmembers}
                          className="me-1"
                          alt=""
                  
                        /> All Members</span></h1>
                        <div className='row'>

                        <div className='col-lg-12'>
                        <div className= 'box-block mb-3' >
                            <h4 className='block-title-name'>Stream Report</h4>
                            {streamReportData.length > 0 ? (
                            <StreamReport data={streamReportData} />
                            ) : (
                            "No Data Found"
                            )}
                        </div>
                        </div>


                        <div className='col-lg-12'>
                        <div className='box-block mb-3 '>
                            <h4 className='block-title-name'>Stream Lead Time Report</h4>
                            {streamLeadTimeReportData.length > 0 ? (
                            <StreamLeadTimeReportGraph data={streamLeadTimeReportData} />
                            ) : (
                            "No Data Found"
                            )}
                        </div>
                        </div>

                        </div>
                    

                    </div>
                </div>
            </div>
            {loader && <Loader />}
        </Layout>
    );

};

export default StreamDashboard;
