import { createSlice } from '@reduxjs/toolkit';

const showCreateCard = createSlice({
  name: 'showCreateCard',
  initialState: false,
  reducers: {
    toggleCreateCard: state => !state
  }
});

export const { toggleCreateCard } = showCreateCard.actions;
export default showCreateCard.reducer;
