import React, { createContext, useContext,  useEffect,  useState } from "react";
import { getTaskDetails, getTimeLogData, getAllComments } from "../network/api";

// Create the ModalContext
const MainContext = createContext();

// Create the ModalProvider
export const MainProvider = ({ children }) => {
    const [tagClicked, setTagClicked] = useState(null);
const handleClickedTag = (tag) => {
    setTagClicked(tag)
}
  return (
    <MainContext.Provider
      value={{
        tagClicked,handleClickedTag
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
export const useMainContext = () => useContext(MainContext);
