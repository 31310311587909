import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import 'chartjs-plugin-datalabels';
import { useNavigate } from "react-router-dom";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);
const UserStreamChart = ({ data }) => {
  const navigate = useNavigate();
  const getKeys = () => {
    const allKeys = new Set();
    Object.values(data).forEach((category) => {
      if (category && category[0]) {
        Object.keys(category[0]).forEach((key) => allKeys.add(key));
      }
    });
    return Array.from(allKeys);
  };
  const categories = Object.keys(data) || [];
  const keys = getKeys() || [];
  const staticColors = ["rgba(255,99,132,1)", "rgba(75,192,192,1)", "rgba(151,120,209)"];
  const datasets = keys?.map((key, index) => {
    const values = categories?.map((category) =>
      parseInt(data[category]?.[0]?.[key] || 0, 10)
    );

    return {
      label: key,
      data: values,
      backgroundColor: staticColors[index],
      barThickness: 50,
    };
  });
  const allValues = Object.values(data).flatMap(category => {
    return category.map(item => {
      return Object.values(item).map(value => parseInt(value, 10) || 0);
    });
  });
  const flattenedArray = allValues.flat();
  const highestValue = Math.max(...flattenedArray);
  const options = {
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'end',
        align: 'end',
        formatter: (value, context) => {
          if (context.datasetIndex === context.chart.data.datasets.length - 1) {
            let sum = 0;
            context.chart.data.datasets.forEach((dataset) => {
              sum += dataset.data[context.dataIndex];
            });
            return sum.toLocaleString();
          } else {
            return '';
          }
        },
        display: true,
      },
    },
    scales: {
      x: {
        stacked: true,
        type: "category",
      },
      y: {
        beginAtZero: true,
        stacked: true,
        max: Math.round((highestValue + 200) / 2) * 2,
        stepSize: 10,
        barPercentage: 1,
      },
    },
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: 'Stacked',
    },
  };
  const emptyOptions = {
    scales: {
      x: {
        stacked: true,
        type: "category",
      },
      y: {
        beginAtZero: true,
        stacked: true,
        stepSize: 10,
        barPercentage: 1,
      },
    },
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: 'Stacked',
    },
  };
  if (!data || Object.keys(data).length === 0) {
    return (
      <div>
        <Bar options={emptyOptions} data={{ labels: [], datasets: [] }} />
        <div>No data available for this graph</div>
      </div>
    );
  }
  return (
    <div>
      <Bar options={options} data={{ labels: categories, datasets }} />
    </div>
  );
};

export default UserStreamChart;
