import React, { useCallback, useEffect, useState } from "react";
import editIcon from "../../assets/images/edit.svg";
import closeCircleIcon from "../../assets/images/close-circle.svg";
import { updateETADate } from "../../lib/network/api";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../lib/contexts/ModalContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import sendButton from "../../assets/images/send.svg";
import { CommentList } from "./CommentList";
import Loader from "../../components/Loader/Loader";

const DetailModalContent = ({
  closeModal,
  taskId,
  userName,
  task,
  commentList,
  handlePostComment,
 
  handleTimeLog,
  fetchTaskDetails,
  taskDetails
}) => {
  const navigate = useNavigate();
  const [loader1, setLoader1] = useState(false);

  const { task_owner } = task;
  const {
    setETA, loader
  } = useModal();
  const getMinDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    setLoader1(true);

  }, [taskDetails, commentList]);
  const handleEditTask = (e) => {
    e.preventDefault();
    const path = `/task?id=${taskId}`;
    navigate(path);
  };
  const getPriorityLabel = (priority) => {
    const priorityMap = {
      H: "High",
      M: "Medium",
      L: "Low",
    };
    return priorityMap[priority] || priority;
  };
  const handleEtaChange = (value) => {
    const requestData = {
      eta_in_date_time: value,
      task_owner_id: task.task_owner_id,
      id: taskDetails?.task_id,
    };
    setETA(value);
    updateETADate(requestData)
      .then((response) => {
        const date = response.data.data.eta_in_date_time.split("T")[0];
        setETA(date);
        fetchTaskDetails();
      })
      .catch((error) => {
        setLoader1(false);

      });
     
  };
  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  const validationSchema = Yup.object({
    comment: Yup.string()
      .required("Comment is required")
      .min(5, "Comment must be at least 5 characters")
      // .matches(/^[^\s][a-zA-Z0-9\s\-.,!"'?/()_$%&:;<>{}]*$/, {
      //   message: "Only alphanumeric are allowed",
      // }),
  });
  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const trimmedComment = values.comment.trim();
      handlePostComment(trimmedComment);
      resetForm();
    },
  });
  return (
    <div
      className="modal_class"
      onClick={handleModalClick}
      style={{ width: "50%", height: "590px", overflow: "auto" }}
    >
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
          Details
        </h1>
        <a href="" onClick={(e) => closeModal(e)}>
          <img
            src={closeCircleIcon}
            alt=""
            data-bs-dismiss="modal"
            className="close-modal-icon"
          />
        </a>
      </div>
      {loader ? (
        <div
          className="modal-body-loading"
          style={{ position: "relative", top: "11rem" }}
        >
          Loading...
        </div>
      ) : (
        <div className="modal-body">
          <h2>{taskDetails ? `${taskDetails.task_id}` : null}</h2>
          <span
            className={`tag_priority ${taskDetails?.task_priority === "H"
                ? ""
                : taskDetails?.task_priority === "L"
                  ? "bg-grey"
                  : "bg-medium"
              }`}
            data-bs-toggle="modal"
            href="#exampleModalToggle"
            role="button"
          >
            {taskDetails ? getPriorityLabel(taskDetails.task_priority) : null}
          </span>{" "}
          {task_owner.trim().toLowerCase() == userName.trim().toLowerCase() && (
            <a
              href=""
              className="edit_detail ms-2"
              onClick={(e) => handleEditTask(e)}
            >
              <img src={editIcon} alt="" />
            </a>
          )}
          <div className="row mt-3">
            {taskDetails && (
              <>
                {taskDetails.comments && taskDetails.comments.length > 0 && (
                  <div className="col-md-12 mb-3">
                    <section className="detail_comments"></section>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="comment_box">
                        <label className="form-label">
                          Add your comment here
                        </label>
                        <textarea
                          className={`form-control ${formik.touched.comment && formik.errors.comment
                              ? "is-invalid"
                              : ""
                            }`}
                          name="comment"
                          id="comment"
                          cols="30"
                          rows="10"
                          placeholder="Enter Comment"
                          value={formik.values.comment}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></textarea>
                        {formik.touched.comment && formik.errors.comment && (
                          <div className="invalid-feedback">
                            {formik.errors.comment}
                          </div>
                        )}
                        {/* <button className="btn_send" type="submit">
                          <img src={sendButton} alt="" />
                        </button> */}
                        <button type="submit" class="btn_send btn btn-primary">Send</button>
                      </div>
                    </form>
                    {/* comment list */}
                    <label className="form-label mb-0 me-3">Comments :</label>
                    {!loader1 ? (
                      <CommentList comments={commentList} />
                    ) : (
                      <div>Loading...</div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailModalContent;
